import { css } from 'styled-components';

export default css`
  .left-header {
    text-align: left;
    font-size: 15px;

    i.warning-no-tax-icon-end {
      margin-left: 0.4rem;
      color: #854d0e;
    }
  }

  td {
    text-align: right;
  }

  div.warning-no-tax {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-top: 1rem;
    padding: 1rem;
    background-color: #fefce8;
    border-radius: 0.375rem;
    border: 1px solid #864e0e80;
    color: #854d0e;

    i.icon-start {
      margin-right: 0.4rem;
    }
  }

  button.retry-pull-tax-information {
    margin-top: 0.5rem;
    width: 100%;
  }
`;
