import common from '../common';
import errors from './errors';
import themes from './theme';
import features from './features';

export default {
  ...common,
  mobileTicket: 'Mobile Ticket',
  ...features,
  save: 'Spara',
  username: 'Användarnamn',
  password: 'Lösenord',
  login: 'Logga in',
  required: 'Logga in',
  light: 'Ljus',
  dark: 'Mörk',
  rememberMe: 'Kom ihåg',
  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Integritetspolicy',
  customer: 'Kund',
  project: 'Projekt',
  isBillable: 'Fakturerbar',
  dispatchDateTime: 'Utgångspunkt',
  orderId: 'Beställa',
  ticketId: 'Följesedel',
  location: 'Lokation',
  vehicleTypeName: 'Fordon',
  product: 'Produkt',
  products: 'Produkt',
  salesTax: 'mammor',
  totalPrice: 'Totalbelopp',
  extendedPrice: 'utökat pris',
  destination: 'Destination',
  productId: 'Produkt ID',
  statusCode: 'Status',
  vehicleTypeId: 'Fordons ID',
  locationId: 'Lokations ID',
  customerId: 'Kund ID',
  projectId: 'Projekt ID',
  dispatchTime: 'Tid',
  showAll: 'Visa alla',
  hideAll: 'Göm alla',
  undoChanges: 'Ångra ändringar',
  resetColumns: 'Återställ',
  displayRowCount: `Visa {count, plural, 
    =0 {0 rows} 
    one {# row} 
    other {# rows} 
  }`,
  columnFilters: 'Filtrera',
  visible: 'Synlig',
  hidden: 'Gömd',
  itemDetail: 'artikeldetalj',
  quantity: 'Kvantitet',
  quantityMaxWaterToAdd: 'Kvantitet (Max vatten att tillsätta: {maxWaterQuantity})',

  unloadingMethod: 'Lossningssätt',
  time: 'Tid',
  eventStatus: 'Status',
  MTQ_abbr: 'm\u00B3',
  EA_abbr: 'EA',
  LTR_abbr: 'L',
  YDQ_abbr: 'CY',
  GLL_abbr: 'GAL',
  MTQ_text: 'Kubikmeter',
  M3_text: 'Kubikmeter',
  EA_text: 'Varje',
  LTR_text: 'Liter',
  YDQ_text: 'Kubikmeter',
  GLL_text: 'Liter',
  ACR_text: 'Tunnland',
  HUR_text: 'Timme',
  INH_text: 'Tum',
  FOT_text: 'Fot',
  YRD_text: 'Gård',
  MMT_text: 'Millimeter',
  CMT_text: 'Centimeter',
  MTR_text: 'Meter',
  SMI_text: 'Mile (stadga mil)',
  KMT_text: 'Kilometer',
  INK_text: 'Kvadrattum',
  FTK_text: 'Kvadrat meter',
  YDK_text: 'Fyrkantig gård',
  MIK_text: 'Kvadrat mil',
  CMK_text: 'Kvadratcentimeter',
  MTK_text: 'Kubikmeter',
  KMK_text: 'Fyrkantig kilometer',
  INQ_text: 'Kubik tum',
  MMQ_text: 'Kubikmillimeter',
  FTQ_text: 'Kubikfot',
  CMQ_text: 'Kubikcentimeter',
  OZA_text: 'Flytande uns',
  PT_text: 'Halvliter',
  QT_text: 'Quart',
  MLT_text: 'Milliliter',
  ONZ_text: 'Uns',
  LBR_text: 'Pund',
  STN_text: 'Ton',
  GRM_text: 'Gram',
  KGM_text: 'Kilogram',
  TNE_text: 'Ton',
  PS_text: 'Psi',
  MPA_text: 'Megapascal',
  C56_text: 'Newton per kvadratmillimeter',
  C62_text: 'Ett',
  NL_text: 'Ladda',
  MT_text: 'Matta',
  P1_text: 'Procent',
  CEL_text: 'Grader Celsius',
  FAH_text: 'Examen Fahrenheit',
  BLL_text: 'Tunna',
  CLT_text: 'Centiliter',
  DLT_text: 'Deciliter',
  GLI_text: 'Gallon',
  '2U_text': 'Megagram',
  SA_text: 'Säck',
  '60_text': 'Procentvikt',
  BG_text: 'Väska',
  SEC_text: 'Andra',
  MIN_text: 'Minut',
  '64_text': 'Pund per kvadrat tum',
  SP_text: 'Hyllpaket',
  MMK_text: 'Kvadratmillimeter',
  MTN_text: 'Metric Ton',
  GT_text: 'Gigaton',
  '/NL_text': 'Per laddning',
  '/EA_text': 'Per kvantitet',
  '/H/ + h_text': 'Per timme',
  '/YQ/ + YDQ_text': 'Per kubikgård',
  '/MQ/ + MTQ_text': 'Per kubikmeter',
  '/MN/ + MIN_text': 'Per minut',
  '/MT_text': 'Per meter',
  DSG_text: 'Dosering',
  '/C_text': 'Per 100 vikt',
  '64_2_text': 'Specifik',
  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,
  M3: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,
  LTR: `{value, plural,
    =0 {0 Liters}
    one {1 Liter}
    other {# Liters}
  }`,
  TO: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,
  ITEM: `{value, plural,
    =0 {0 Items}
    one {1 Item}
    other {# Items}
  }`,
  YDQ_short: `{value, plural,
    =0 {0 CY}
    one {1 CY}
    other {# CY}
  }`,
  YDQ: `{value, plural,
    =0 {0 Cubic Yards}
    one {1 Cubic Yard}
    other {# Cubic Yards}
  }`,
  GLL: `{value, plural,
    =0 {0 Gallons}
    one {1 Gallon}
    other {# Gallons}
  }`,
  EA: `{value, plural,
    =0 {0 Each}
    one {1 Each}
    other {# Each}
  }`,
  MIN: `{value, plural,
    =0 {0 Minutes}
    one {1 Minute}
    other {# Minutes}
  }`,
  ACR: `{value, plural,
    =0 {0 Acres}
    one {1 Acre}
    other {# Acres}
  }`,
  HUR: `{value, plural,
    =0 {0 Hours}
    one {1 Hour}
    other {# Hours}
  }`,
  INH: `{value, plural,
    =0 {0 Inches}
    one {1 Inch}
    other {# Inches}
  }`,
  FOT: `{value, plural,
    =0 {0 Feet}
    one {1 Foot}
    other {# Feet}
  }`,
  YRD: `{value, plural,
    =0 {0 Yards}
    one {1 Yard}
    other {# Yards}
  }`,
  MMT: `{value, plural,
    =0 {0 Millimetres}
    one {1 Millimetre}
    other {# Millimetres}
  }`,
  CMT: `{value, plural,
    =0 {0 Centimetres}
    one {1 Centimetre}
    other {# Centimetres}
  }`,
  MTR: `{value, plural,
    =0 {0 Metres}
    one {1 Metre}
    other {# Metres}
  }`,
  SMI: `{value, plural,
    =0 {0 Miles (Statute Miles)}
    one {1 Mile (Statute Mile)}
    other {# Miles (Statute Miles)}
  }`,
  KMT: `{value, plural,
    =0 {0 Kilometres}
    one {1 Kilometre}
    other {# Kilometres}
  }`,
  INK: `{value, plural,
    =0 {0 Square Inches}
    one {1 Square Inch}
    other {# Square Inches}
  }`,
  FTK: `{value, plural,
    =0 {0 Square Feet}
    one {1 Square Foot}
    other {# Square Feet}
  }`,
  YDK: `{value, plural,
    =0 {0 Square Yards}
    one {1 Square Yard}
    other {# Square Yards}
  }`,
  MIK: `{value, plural,
    =0 {0 Square Miles}
    one {1 Square Mile}
    other {# Square Miles}
  }`,
  CMK: `{value, plural,
    =0 {0 Square Centimetres}
    one {1 Square Centimetre}
    other {# Square Centimetres}
  }`,
  MTK: `{value, plural,
    =0 {0 Cubic Metres}
    one {1 Cubic Metre}
    other {# Cubic Metres}
  }`,
  KMK: `{value, plural,
    =0 {0 Square Kilometres}
    one {1 Square Kilometre}
    other {# Square Kilometres}
  }`,
  INQ: `{value, plural,
    =0 {0 Cubic Inches}
    one {1 Cubic Inch}
    other {# Cubic Inches}
  }`,
  MMQ: `{value, plural,
    =0 {0 Cubic Millimetres}
    one {1 Cubic Millimetre}
    other {# Cubic Millimetres}
  }`,
  FTQ: `{value, plural,
    =0 {0 Cubic Feet}
    one {1 Cubic Foot}
    other {# Cubic Feet}
  }`,
  CMQ: `{value, plural,
    =0 {0 Cubic Centimetres}
    one {1 Cubic Centimetre}
    other {# Cubic Centimetres}
  }`,
  OZA: `{value, plural,
    =0 {0 Fluid Ounces}
    one {1 Fluid Ounce}
    other {# Fluid Ounces}
  }`,
  PT: `{value, plural,
    =0 {0 Pints}
    one {1 Pint}
    other {# Pints}
  }`,
  QT: `{value, plural,
    =0 {0 Quarts}
    one {1 Quart}
    other {# Quarts}
  }`,
  MLT: `{value, plural,
    =0 {0 Millilitres}
    one {1 Millilitre}
    other {# Millilitres}
  }`,
  ONZ: `{value, plural,
    =0 {0 Ounces}
    one {1 Ounce}
    other {# Ounces}
  }`,
  LBR: `{value, plural,
    =0 {0 Pounds}
    one {1 Pound}
    other {# Pounds}
  }`,
  STN: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,
  GRM: `{value, plural,
    =0 {0 Grams}
    one {1 Gram}
    other {# Grams}
  }`,
  KGM: `{value, plural,
    =0 {0 Kilograms}
    one {1 Kilogram}
    other {# Kilograms}
  }`,
  TNE: `{value, plural,
    =0 {0 Tonnes}
    one {1 Tonne}
    other {# Tonnes}
  }`,
  PS: `{value, plural,
    =0 {0 PSI}
    one {1 PSI}
    other {# PSI}
  }`,
  MPA: `{value, plural,
    =0 {0 Megapascals}
    one {1 Megapascal}
    other {# Megapascals}
  }`,
  C56: `{value, plural,
    =0 {0 Newtons Per Square Millimetre}
    one {1 Newton Per Square Millimetre}
    other {# Newtons Per Square Millimetre}
  }`,
  C62: `{value, plural,
    =0 {0 One}
    one {1 One}
    other {# One}
  }`,
  NL: `{value, plural,
    =0 {0 Loads}
    one {1 Load}
    other {# Loads}
  }`,
  MT: `{value, plural,
    =0 {0 Mats}
    one {1 Mat}
    other {# Mats}
  }`,
  P1: `{value, plural,
    =0 {0 Percent}
    one {1 Percent}
    other {# Percent}
  }`,
  CEL: `{value, plural,
    =0 {0 Degrees Celsius}
    one {1 Degree Celsius}
    other {# Degrees Celsius}
  }`,
  FAH: `{value, plural,
    =0 {0 Degrees Fahrenheit}
    one {1 Degree Fahrenheit}
    other {# Degrees Fahrenheit}
  }`,
  BLL: `{value, plural,
    =0 {0 Barrels}
    one {1 Barrel}
    other {# Barrels}
  }`,
  CLT: `{value, plural,
    =0 {0 Centilitres}
    one {1 Centilitre}
    other {# Centilitres}
  }`,
  DLT: `{value, plural,
    =0 {0 Decilitres}
    one {1 Decilitre}
    other {# Decilitres}
  }`,
  GLI: `{value, plural,
    =0 {0 Gallons}
    one {1 Gallon}
    other {# Gallons}
  } `,
  '2U': `{value, plural,
    =0 {0 Megagrams}
    one {1 Megagram}
    other {# Megagrams}
  }`,
  SA: `{value, plural,
    =0 {0 Sacks}
    one {1 Sack}
    other {# Sacks}
  }`,
  60: `{value, plural,
    =0 {0 Percent Weight}
    one {1 Percent Weight}
    other {# Percent Weight}
  }`,
  BG: `{value, plural,
    =0 {0 Bags}
    one {1 Bag}
    other {# Bags}
  }`,
  SEC: `{value, plural,
    =0 {0 Seconds}
    one {1 Second}
    other {# Seconds}
  }`,
  64: `{value, plural,
    =0 {0 Pounds per square inch - Gauge}
    one {1 Pound per square inch - Gauge}
    other {# Pounds per square inch - Gauge}
  }`,
  SP: `{value, plural,
    =0 {0 Shelf Packages}
    one {1 Shelf Package}
    other {# Shelf Packages}
  }`,
  MMK: `{value, plural,
    =0 {0 kvadratmillimeter}
    one {1 kvadratmillimeter}
    other {# kvadratmillimeter}
  }`,
  MTN: `{value, plural,
    =0 {0 metriska ton}
    one {1 ton}
    other {# ton}
  }`,
  GT: `{value, plural,
    =0 {0 gigaton}
    one {1 Gigaton}
    other {# gigaton}
  }`,
  '/NL': `{value, plural,
    =0 {0 per last}
    one {1 per last}
    other {# per last}
  }`,
  '/EA': `{value, plural,
    =0 {0 per kvantitet}
    one {1 per kvantitet}
    other {# per kvantitet}
  }`,
  '/H/ + h': `{value, plural,
    =0 {0 per timme}
    one {1 per timme}
    other {# per timme}
  }`,
  '/YQ/ + YDQ': `{value, plural,
    =0 {0 per kubikgård}
    one {1 per kubikgård}
    other {# per kubikgård}
  }`,
  '/MQ/ + MTQ': `{value, plural,
    =0 {0 per kubikmeter}
    one {1 per kubikmeter}
    other {# per kubikmeter}
  }`,
  '/MN/ + MIN': `{value, plural,
    =0 {0 per minut}
    one {1 per minut}
    other {# per minut}
  }`,
  '/MT': `{value, plural,
    =0 {0 per meter}
    one {1 per meter}
    other {# per meter}
  }`,
  DSG: `{value, plural,
    =0 {0 dosering}
    one {1 dosering}
    other {# dosering}
  }`,
  '/C': `{value, plural,
    =0 {0 Per 100 vikt}
    one {1 Per 100 vikt}
    other {# Per 100 vikt}
  }`,
  '64_2': `{value, plural,
    =0 {0 Specifik}
    one {1 Specifik}
    other {# Specifik}
  }`,
  LTR_short: `{value, plural,
  =0 {0 L}
  one {1 L}
  other {# L}
}`,
  TO_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# Tn}
}`,
  ITEM_short: `{value, plural,
  =0 {0 Items}
  one {1 Item}
  other {# Items}
}`,
  GLL_short: `{value, plural,
  =0 {0 GAL}
  one {1 GAL}
  other {# GAL}
}`,
  EA_short: `{value, plural,
  =0 {0 EA}
  one {1 EA}
  other {# EA}
}`,
  MIN_short: `{value, plural,
  =0 {0 MIN}
  one {1 MIN}
  other {# MIN}
}`,
  ACR_short: `{value, plural,
  =0 {0 AC}
  one {1 AC}
  other {# AC}
}`,
  HUR_short: `{value, plural,
  =0 {0 HR}
  one {1 HR}
  other {# HR}
}`,
  INH_short: `{value, plural,
  =0 {0 IN}
  one {1 IN}
  other {# IN}
}`,
  FOT_short: `{value, plural,
  =0 {0 FT}
  one {1 FT}
  other {# FT}
}`,
  YRD_short: `{value, plural,
  =0 {0 YD}
  one {1 YD}
  other {# YD}
}`,
  MMT_short: `{value, plural,
  =0 {0 MM}
  one {1 MM}
  other {# MM}
}`,
  CMT_short: `{value, plural,
  =0 {0 CM}
  one {1 CM}
  other {# CM}
}`,
  MTR_short: `{value, plural,
  =0 {0 M}
  one {1 M}
  other {# M}
}`,
  SMI_short: `{value, plural,
  =0 {0 Miles (ST MI)}
  one {1 Mile ((ST MI)}
  other {# Miles ((ST MI)}
}`,
  KMT_short: `{value, plural,
  =0 {0 KM}
  one {1 KM}
  other {# KM}
}`,
  INK_short: `{value, plural,
  =0 {0 SQ IN}
  one {1 SQ IN}
  other {# SQ IN}
}`,
  FTK_short: `{value, plural,
  =0 {0 FT2}
  one {1 FT2}
  other {# FT2}
}`,
  YDK_short: `{value, plural,
  =0 {0 SQ YD}
  one {1 SQ YD}
  other {# SQ YD}
}`,
  MIK_short: `{value, plural,
  =0 {0 SQ MI}
  one {1 SQ MI}
  other {# SQ MI}
}`,
  CMK_short: `{value, plural,
  =0 {0 CM2}
  one {1 CM2}
  other {# CM2}
}`,
  MTK_short: `{value, plural,
  =0 {0 M3}
  one {1 M3}
  other {# M3}
}`,
  KMK_short: `{value, plural,
  =0 {0 KM2}
  one {1 KM2}
  other {# KM2}
}`,
  INQ_short: `{value, plural,
  =0 {0 CU IN}
  one {1 CU IN}
  other {# CU IN}
}`,
  MMQ_short: `{value, plural,
  =0 {0 MM3}
  one {1 MM3}
  other {# MM3}
}`,
  FTQ_short: `{value, plural,
  =0 {0 FT3}
  one {1 FT3}
  other {# FT3}
}`,
  CMQ_short: `{value, plural,
  =0 {0 CM3}
  one {1 CM3}
  other {# CM3}
}`,
  OZA_short: `{value, plural,
  =0 {0 FL OZ}
  one {1 FL OZ}
  other {# FL OZ}
}`,
  PT_short: `{value, plural,
  =0 {0 PT}
  one {1 PT}
  other {# PT}
}`,
  QT_short: `{value, plural,
  =0 {0 QT}
  one {1 QT}
  other {# QT}
}`,
  MLT_short: `{value, plural,
  =0 {0 mL}
  one {1 mL}
  other {# mL}
}`,
  ONZ_short: `{value, plural,
  =0 {0 OZ}
  one {1 OZ}
  other {# OZ}
}`,
  LBR_short: `{value, plural,
  =0 {0 LB}
  one {1 LB}
  other {# LB}
}`,
  STN_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# TN}
}`,
  GRM_short: `{value, plural,
  =0 {0 G}
  one {1 G}
  other {# G}
}`,
  KGM_short: `{value, plural,
  =0 {0 KG}
  one {1 KG}
  other {# KG}
}`,
  TNE_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# TN}
}`,
  PS_short: `{value, plural,
  =0 {0 PSI}
  one {1 PSI}
  other {# PSI}
}`,
  MPA_short: `{value, plural,
  =0 {0 MPa}
  one {1 MPa}
  other {# MPa}
}`,
  C56_short: `{value, plural,
  =0 {0 Newtons Per Square Millimeter}
  one {1 Newton Per Square Millimeter}
  other {# Newtons Per Square Millimeter}
}`,
  C62_short: `{value, plural,
  =0 {0 One}
  one {1 One}
  other {# One}
}`,
  NL_short: `{value, plural,
  =0 {0 Loads}
  one {1 Load}
  other {# Loads}
}`,
  MT_short: `{value, plural,
  =0 {0 Mats}
  one {1 Mat}
  other {# Mats}
}`,
  P1_short: `{value, plural,
  =0 {0 %}
  one {1 %}
  other {# %}
}`,
  CEL_short: `{value, plural,
  =0 {0 C}
  one {1 C}
  other {# C}
}`,
  FAH_short: `{value, plural,
  =0 {0 F}
  one {1 F}
  other {# F}
}`,
  BLL_short: `{value, plural,
  =0 {0 BBL}
  one {1 BBL}
  other {# BBL}
}`,
  CLT_short: `{value, plural,
  =0 {0 cL}
  one {1 cL}
  other {# cL}
}`,
  DLT_short: `{value, plural,
  =0 {0 dL}
  one {1 dL}
  other {# dL}
}`,
  GLI_short: `{value, plural,
  =0 {0 GAL}
  one {1 GAL}
  other {# GAL}
}`,
  '2U_short': `{value, plural,
  =0 {0 Megagrams}
  one {1 Megagram}
  other {# Megagrams}
}`,
  SA_short: `{value, plural,
  =0 {0 Sacks}
  one {1 Sack}
  other {# Sacks}
}`,
  '60_short': `{value, plural,
  =0 {0 Percent Weight}
  one {1 Percent Weight}
  other {# Percent Weight}
}`,
  BG_short: `{value, plural,
  =0 {0 Bags}
  one {1 Bag}
  other {# Bags}
}`,
  SEC_short: `{value, plural,
  =0 {0 SEC}
  one {1 SEC}
  other {# SEC}
}`,
  '64_short': `{value, plural,
  =0 {0 Pounds per square inch - Gauge}
  one {1 Pound per square inch - Gauge}
  other {# Pounds per square inch - Gauge}
}`,
  SP_short: `{value, plural,
  =0 {0 Shelf Packages}
  one {1 Shelf Package}
  other {# Shelf Packages}
}`,
  MMK_short: `{value, plural,
    =0 {0 kvadratmillimeter}
    one {1 kvadratmillimeter}
    other {# kvadratmillimeter}
}`,
  MTN_short: `{value, plural,
  =0 {0 MT}
  one {1 MT}
  other {# MT}
}`,
  GT_short: `{value, plural,
  =0 {0 GT}
  one {1 GT}
  other {# GT}
}`,
  '/NL_short': `{value, plural,
  =0 {0 /Load}
  one {1 /Load}
  other {# /Load}
}`,
  '/EA_short': `{value, plural,
  =0 {0 /EA}
  one {1 /EA}
  other {# /EA}
}`,
  '/H/ + h_short': `{value, plural,
  =0 {0 /Timme}
  one {1 /Timme}
  other {# /Timme}
}`,
  '/YQ/ + YDQ_short': `{value, plural,
  =0 {0 /CY}
  one {1 /CY}
  other {# /CY}
}`,
  '/MQ/ + MTQ_short': `{value, plural,
  =0 {0 /M3}
  one {1 /M3}
  other {# /M3}
}`,
  '/MN/ + MIN_short': `{value, plural,
  =0 {0 /MIN}
  one {1 /MIN}
  other {# /MIN}
}`,
  '/MT_short': `{value, plural,
  =0 {0 /M}
  one {1 /M}
  other {# /M}
}`,
  DSG_short: `{value, plural,
  =0 {0 Dosering}
  one {1 Dosering}
  other {# Dosering}
}`,
  '/C_short': `{value, plural,
  =0 {0 /C}
  one {1 /C}
  other {# /C}
}`,
  '64_2_short': `{value, plural,
  =0 {0 Specifik}
  one {1 Specifik}
  other {# Specifik}
}`,
  mixIdLabel: 'Blandning / Beskrivning',
  orderedQtyLabel: 'Beställd mängd',
  loadedQtyLabel: 'Lastad mängd',
  addedByCustomer: 'Tillagd av kunden',
  PRINTED: 'Skapad',
  LOADING_STARTED: 'Börja ladda',
  LOADING_COMPLETE: 'Finish Loading',
  TO_JOB: 'Till arbetsplats',
  ARRIVE_JOB: 'Ankomst arbetsplats',
  UNLOADING: 'Lossad',
  END_UNLOADING: 'Slut lossning',
  LEAVE_JOB: 'Lämna arbetsplats',
  IN_YARD: 'På fabriksområde',
  PUMPING_STARTED: 'Starta pumpen',
  PUMPING_COMPLETE: 'Slutpump',
  READY_TO_PUMP: 'Redo att pumpa',
  FIRST_WCCONTACT: 'Första vattencementkontakt',
  plantName: 'Fabrik',
  plantAddress: 'Fabrik adress',
  loadingScheduleStart: 'Planerad lastningstid',
  deliveryAddress: 'Leveransadress',
  phoneNumber: 'Telefonnummer',
  unloadingScheduleStart: 'Planerad lossningstid',
  agreedUnloading: 'Beställda lossningstider',
  deliveryInformation: 'Leveransinformation',
  next: 'Nästa',
  material: 'Produkt',
  loadingPoint: 'Lastningsställe',
  unloadingPoint: 'Losnningsställe',
  comment: 'Kommentar',
  sapDeliveryNumber: 'SAP följesedelsnummer',
  salesOrderNumber: 'SAP ordernummer',
  wcDateTime: 'datum och tid för W/C kontakt',
  po: 'PO nummer',
  orderedByName: 'Beställare',
  orderedByPhone: 'Beställare tel.',
  resourceId: 'Fordons ID',
  totalLoadedQty: 'Totalt lastad mängd inkl detta lass',
  spacing: 'Intervall',
  deliveryFlow: 'Gjuthastighet',
  timeOnSite: 'Beställd lossningstis',
  salesText: 'Sälj text',
  strengthClass: 'Hållfasthetsklass',
  environmentExposure: 'Miljöpåverkan',
  metExposureClasses: 'Uppfyllda exponeringsklasser',
  dmax: 'Dmax',
  standardCert: 'Standard',
  workabilityTarget: 'Beställt sättmått',
  stoneQuality: 'Stenkvalitet',
  sandQuality: 'Sandkvalitet',
  targetWC: 'Mål W/C -förhållande från receptet',
  controlClass: 'Kontrollklass',
  chlorideClass: 'Kloridhaltsklass',
  typeAndStrength1: 'Cementtyp 1',
  typeAndStrength2: 'Cementtyp 2',
  typeOfAddition: 'Tillsatsmaterial',
  typeOfAdmixture: 'Tillsatsmedel',
  orderedTemp: 'Beställd temperatur',
  fibers1: 'Typ och innehåll fiber 1',
  fibers2: 'Typ och innehåll fiber 1',
  calculatedMaturityTime: 'Beräknad mognadstid från W/C -kontakttid',
  mixIdentity: 'Blandningsidentitet',
  grade: 'Kvalitet',
  mixType: 'Blandningstyp',
  maxAggSize: 'Max Agg -storlek',
  maxAggType: 'Max Agg -typ',
  cementType: 'Cementtyp',
  admixtures: 'Blandningar',
  addmixDescription: 'AddMix Beskrivning',
  slump: 'Nedgång',
  minCementContent: 'Min cementinnehåll',
  maxWatCemRatio: 'Max vatten/cementförhållande',
  maxWaterToAdd: 'Max vatten att lägga till',
  mortarMinWorkingLife: 'Murbruk min arbetsliv',
  code: 'Koda',
  dcClass: 'dcClass',
  chloride: 'Klorid',
  signedByHanson: 'Signerad av Hanson',
  warmStamp: 'Varmstämpel',
  coldStamp: 'Förkylning',
  containsSikatard: 'Innehåller sikatard',
  containsDelvo: 'Innehåller Delvo',
  generalDelivery: 'Generell leveransinformation',
  materialInfo: 'Produktinformation',
  water: 'Vatten',
  materialService: 'Material/Service',
  returnedMaterial: 'Returbetong',
  returnedConcrete: 'Returnerad betong',
  waterAdded: 'Vatten tillsatt',
  concreteOnTruck: 'Betong kvar',
  concreteOnTruckUom: 'Standardbetong på lastbil UOM',
  reason: 'Orsak',
  customerAddedWater: 'Tillsatt vatten enligt kund',
  addWater: 'Tillsätt vatten',
  addedAt: 'Tillagd',
  addMaterialService: 'Tillsätt material/service',
  customerAddedProduct: 'Tillsatt produkt enligt kund',
  addReturnedMaterial: 'Lägg till returnerat material',
  leftover: 'Returbetong',
  submitAcceptance: 'Acceptera',
  accepted: 'Accepterad',
  rejected: 'Avvisad',
  customerAcceptance: 'Kund acceptans',
  finalize: 'Avsluta',
  materialSummary: 'Summering',
  mix: 'Produkt',
  ordered: 'Beställt',
  loaded: 'Lastad mängd',
  totalOrdered: 'Total orderkvantitet',
  loadedOrderQuantity: 'Laddad beställningskvantitet',
  loadedOrder: 'Laddad beställning',
  driverComment: 'Chaufförskommentar',
  customerComment: 'Kundkommentar',
  editComment: 'Redigera kommentar',
  addComment: 'Lägg till kommentar',
  additionalInfo: 'Ytterliggare information',
  onBoard: 'I bilen',
  driver: 'Chaufför',
  commentInstruction: 'Kommnetarsunstruktion',
  signatureInstruction: 'Signatur instruktion',
  customerSignature: 'Kund signatur',
  general: 'Generellt',
  status: 'Status',
  updateStatusTime: 'Uppdatera status tid',
  delete: 'Radera',
  cancel: 'Avbryt',
  back: 'Tillbaka',
  add: 'Lägg till',
  done: 'Gjort',
  ok: 'OK',
  decline: 'Nedgång',
  Submit: 'Skicka in',
  submit: 'Skicka in',
  Time: 'Tid',
  clear: 'Klar',
  lastRefresh: 'Sista uppdatering',
  refreshEnabled: 'Aktivera uppdatering',
  id: 'ID',
  name: 'Namn',
  invoiceable: 'Fakturerbar',
  provideSignature: 'Fakturerbar',
  ticket: 'Följesedel',
  details: 'Detaljer',
  qrCode: 'QR',
  driverAcceptanceReason: 'Orsak',
  driverAcceptance: 'Chaufförs accept',
  'payment-method': 'Betalningsmetoder',
  'add-payment-method': 'Lägg till betalningsmetod',
  'reason-code': 'Orsakskod',
  'add-reason-code': 'Lägg till orsakskod',
  'qr-code': 'QR kod',
  'unloading-method': 'Lossningssätt',
  'add-unloading-method': 'Lägg till lossningssätt',
  'driver-comment': 'Chaufförs kommentar',
  'add-driver-comment': 'Lägg till chaufförs kommentar',
  'driver-sellable': 'Förare sellable',
  vehicle: 'Fordon',
  'add-vehicle': 'Lägga till fordon',
  'vehicle-type': 'Fordonstyper',
  vehicleTypes: 'Fordonstyper',
  vehicleType: 'fordonstyp',
  'add-vehicle-type': 'Lägg till fordonstyp',
  countries: 'Länder',
  country: 'Landa',
  type: 'Typ',
  uom: 'Måttenhet',
  mandatory: 'Obligatorsik',
  qrTemplate: 'QR kod mall',
  includeMixInfo: 'Inkludera blandningsinformation i QR kod',
  includeTicketEvents: 'Inkludera följesedelshändelser i QR kod',
  quantityField: 'Aktivera kvantitets fält',
  concreteOnTruckField: 'Aktivera betong på bilen fält',
  materialOnTruckField: 'Aktivera material på bilen fält',
  timeField: 'Aktivera tidsfält',
  reasonField: 'Aktivera orsaks fält',
  driverInstructions: 'Chaufförs instruktioner',
  waitingForTicket: 'Väntar på biljett...',
  createTestTicketForTruck: 'Skapa testbiljett för lastbil',
  exceedsLoadedQuantity: 'Värde kan inte överskrida lastad kvantitet {loadedQuantity}',
  maxQuantity: 'Maximal kvantitet',
  maxQuantityError: 'Kvantitet kan inte överskrida definierad maximal mängd {maxQty}',
  maxValueError: 'Värdet kan inte överstiga definierat maximalt {maxQty}',
  minValueError: 'Värdet kan inte vara mindre än definierat minimum {minqty}',
  notAuthorized: 'Inte behörig',
  enterTruckNumber: 'Ange lastbilsnummer',
  setTruckNumber: 'Ställ in lastbilsnummer',
  commentList: 'Kommentarlista',
  list: 'Lista',
  gridPreferences: 'Rutnätspreferenser',
  loadingUnloadingPoints: 'Laddar/lossningspoäng',
  activity: 'Aktivitet',
  crn: 'Crn',
  update: 'Uppdatering',
  CONCRETE: 'Betong',
  WATER: 'Vatten',
  ADDITIVE: 'Tillsats',
  'submit-signature-button': 'Acceptera',
  'cancel-signature-button': 'Annullera',
  uomCode: 'Standard UOM',
  'product-type': 'Produkttyper',
  'add-product-type': 'Lägg till produkttyp',
  'add-product': 'Lägg till produkt',
  'setup_driver-comment': 'Förarkommentarer',
  setup_product: 'Produkter',
  'setup_product-type': 'Produkttyper',
  'setup_qr-code': 'QR -kod',
  'setup_reason-code': 'Orsakskoder',
  'setup_unloading-method': 'Lossningsmetoder',
  'setup_entity-mt': 'Företag',
  setup_vehicle: 'Fordon',
  'setup_vehicle-type': 'Fordonstyper',
  'setup_data-retentions': 'Behållning',
  'add-data-retentions': 'Lägg till datalagring',
  'data-retentions': 'Behållning',
  setup_carriers: 'Transportör',
  'add-carriers': 'Lägga till transportör',
  carriers: 'Transportör',
  'setup_concrete-classes': 'Materiell efterlevnad',
  'add-concrete-classes': 'Lägg till materiell efterlevnad',
  'concrete-classes': 'Materiell efterlevnad',
  'setup_forms-and-waivers': 'Formulär och undantag',
  'add-forms-and-waivers': 'Lägg till formulär och undantag',
  'forms-and-waivers': 'Formulär och undantag',
  companyFleet: 'Företagsflotta',
  category: 'Kategori',
  interval: 'Intervall',
  orders: 'Beställningar och biljetter',
  '30days': '30 dagar',
  '60days': '60 dagar',
  '90days': '90 dagar',
  '1year': '1 år',
  '3years': '3 år',
  waterMandatory: 'Vatten obligatorisk',
  qrCodeTemplate: 'QR -kodmall',
  waterConfig: 'Valfria fält',
  waterProduct: 'Vatten',
  waterProductType: 'Vattenprodukttyp',
  defaultWaterProduct: 'Standardvattenprodukt',
  invalidTemplate: 'Ogiltig mall',
  siteAddress: 'Platsadress',
  siteContactName: 'Webbplatskontaktnamn',
  siteContactPhoneNumber: 'Webbplatskontakt telefonnummer',
  'clear-signature-button': 'Tydlig signatur',
  customerCommentEnabled: 'Aktivera kundkommentarer',
  driverCommentEnabled: 'Aktivera förarkommentarer',
  driverCommentMaxCharacters: 'Maxlängd',
  customerCommentMaxCharacters: 'Maxlängd',
  timeZone: 'Tidszon',
  driverDidNotAdd: 'Föraren lägger inte till',
  maxLineItems: 'Max antal rader',
  oneLineItem: 'Måste ha minst en rad',
  signatureSiteContact: 'Köparens namn',
  ticketEvents: 'Biljettevenemang',
  hideEvent: 'Dölj händelse',
  editable: 'Redigerbar',
  ticketDetail: `Biljett #{value}`,
  statusTimes: 'Status',
  materialAndDeliveryInfo: 'Material- och leveransinformation',
  customerRejection: 'Kundavslag',
  'submit-rejection-button': 'Skicka avslag',
  accept: 'Acceptera',
  reject: 'Avvisa laddning',
  signature: 'Signatur',
  'no-signature': 'Ingen signatur',
  unFinalizePassword: 'Unfinalisera lösenord',
  waterInWorkflow: 'Aktivera vattensteg i arbetsflödet',
  multicountry: 'Flertal',
  timestampAfterSequence: 'VARNING: Tiden ska inte vara före tidigare tidsstämpel',
  timestampBeforeSequence: 'VARNING: Tiden ska inte vara efter nästa tidsstämpel',
  noRestQuantity: 'Inget returnerat material',
  returnedMaterialMandatory: 'Måste interagera med returnerat materialsteg',
  driverCommentMaxChars: 'Längden kan inte överstiga max av {MaxLength} -tecken.',
  QrCodeEnabled: 'Aktivera QR -kod',
  QrMethod_linkBased: 'Länkbaserad',
  QrMethod_contentBased:
    'Innehållsbaserad, OBS: Innehållsbaserade QR-koder har teckenbegränsningar som kan orsaka problem över 1024 tecken.',
  QrMethod_contentBasedWarning:
    'OBS: Den här QR-koden har {amount} tecken som kanske inte går att läsa av vissa telefoner.',
  returnedMaterialInWorkflow: 'Aktivera returnerat materialsteg i arbetsflödet',
  materialServiceInWorkflow: 'Aktivera material/servicesteg i arbetsflödet',
  totalPumpedQuantity: 'Total pumpad mängd',
  concrete: 'Betong',
  materialComplianceEnabled: 'Aktivera materialöverensstämmelse',
  pumpStatusTimes: 'Pumpstatusstider',
  pollingInterval: 'Pollingintervall (sekunder)',
  tenSecs: 'Intervallet måste vara minst 10 sekunder',
  materialCompliance: 'Materiell efterlevnad',
  consistenceValue: 'Konsistens - Nedgång',
  consistenceIsCompliant: 'Konsistens överensstämmer med BS8500-2',
  airContentValue: 'Luftinnehåll',
  airContentIsCompliant: 'Luftinnehåll uppfyller BS8500-2',
  consistenceIsCompliantShort: 'Konsistens uppfyller',
  airContentIsCompliantShort: 'Luftinnehåll uppfyller',
  compliance: 'Materialöverensstämmelse med BS8500-2',
  termsAndConditionsEnabled: 'Aktivera villkoren formulär',
  termsAndConditions: 'Villkor',
  damageWaiverEnabled: 'Aktivera skador på undantag',
  damageWaiver: 'Skadeavstående',
  damageWaiverAcknowledged: 'Avstående skada erkänd',
  dynamicWaiver: 'Dynamisk undantag',
  dynamicWaiverEnabled: 'Aktivera dynamiskt undantagsformulär',
  ticketListEnabled: 'Aktivera biljettlistan',
  clearTicketAfterSubmission: 'Rensa biljett efter inlämning',
  keepTicketOpenAfterSubmission: 'Håll biljett öppen efter inlämning',
  displayTicketList: 'Visningsbiljettlista',
  supportedLanguages: 'Stödsspråk',
  allow: 'Tillåta',
  default: 'Standard',
  translations: 'ÖVERTNINGAR',
  family_name: 'Efternamn',
  given_name: 'Förnamn',
  email: 'E-post',
  role: 'Rulla',
  inviteUser: 'Bjuda in användare',
  carrier: 'Bärare',
  emailOrPhoneNumber: 'E-post',
  usersEditor: 'Användarredaktör',
  verifyPassword: 'Verifiera lösenord',
  verificationCode: 'Verifierings kod',
  register: 'Registrera',
  registerInstructions: 'Fyll i detta formulär för att slutföra din registrering.',
  'password-requirements':
    'Ditt lösenord måste bestå av minst 8 tecken, innehålla minst 1 versal, 1 gemen, 1 siffra och 1 specialtecken.',
  passwordMinCharacters: 'Lösenordet måste innehålla 8 eller fler tecken.',
  passwordMaxCharacters: 'Lösenord kan inte innehålla mer än 99 tecken.',
  passwordLowerCase: 'Lösenordet måste innehålla 1 eller flera små bokstäver.',
  passwordUpperCase: 'Lösenordet måste innehålla 1 eller flera stora bokstav.',
  passwordNumber: 'Lösenordet måste innehålla 1 eller mer numeriskt tecken.',
  passwordSpecialCharacter: 'Lösenordet måste innehålla 1 eller fler specialtecken.',
  passwordsDontMatch: 'Lösenord matchar inte!',
  success: 'Framgång!',
  'registration-success': 'Din registrering var framgångsrik.',
  'post-registration-instructions': 'Du har slutfört registreringsprocessen. Du kan nu logga in.',
  createDate: 'skapa datum',
  customerPo: 'Kund PO -nummer',
  specification: 'Specifikation',
  description: 'Beskrivning',
  classType: 'Klasstyp',
  minimumValue: 'Minimivärde',
  maximumValue: 'Maximalt värde',
  TrackIt: 'Spåra det',
  trackIt3PContext: 'Trackit 3p -sammanhang',
  inviteLanguage: 'Bjuda språk',
  truckNumber: 'Lastbilsnummer',
  adverseTemperature:
    'Omgivningstemperatur vid blandningstillfället var sådan att vi inte kan garantera att betongen kommer att vara i temperaturintervallen på 5-35oC som anges i EN 206.',
  environmentalNote:
    'Denna belastning innehåller ett miljömedlet som inte överstiger 0,03 viktprocent av betongen. Vatten som innehåller detta material för tillverkning av betong kommer att följa BS EN 1008.',
  unloadingMethodEnabled: 'Aktivera lossningsmetod',
  warrantyVoided: 'Materialgaranti ogiltig',
  isCertificationLossTriggerForReason: 'Orsakar certifieringsförlust',
  isCertificationLossTriggerForProduct: 'Orsakar certifieringsförlust',
  materialWarrantyVoidedEnabled:
    'När produkten för certifieringsförlust eller produkt & orsakskod används, visa en anmärkning om certifieringsförlust vid tidpunkten för underskrift.',
  'setup_templates-mt': 'Mallar',
  copyFrom: 'Kopiera från...',
  copy: 'Kopiera',
  Copy: 'Kopiera',
  users: 'Användare',
  setup: 'Uppstart',
  driverExperience: 'Förarerfarenhet',
  ticketList: 'Biljettlista',
  content: 'Innehåll',
  WARNINGS: 'Varningar',
  TERMS: 'Villkor',
  DAMAGE: 'Skada',
  ADDITIONALWAIVER: 'Ytterligare undantag',
  DYNAMICWAIVER: 'Dynamisk undantag',
  source: 'Källa',
  sensorUnable: 'Sensorn kan inte bestämma överbliven kvantitet.',
  PROBE: 'Sond',
  DRIVER: 'Förare',
  DRS: 'Drs',
  waterReleaseFormEnabled: 'Aktivera vattenutsläppsformulär',
  waterRelease: 'Vattenfrigöring',
  waterReleaseAcknowledged: 'Vattenutsläpp kvitterat',
  'assessment-risk': 'Riskbedömning',
  'setup_assessment-risk': 'Riskbedömning',
  'add-assessment-risk': 'Lägg till riskbedömning',
  enterRecipientsButton: 'Ange mottagare',
  recipientsInstructions: 'Ange e-post, avgränsad med kommatecken',
  enter: 'Stiga på',
  invalidEmailAddress: 'Ogiltig e-postadress',
  personalDataPolicy:
    'Genom att ange personuppgifter i detta formulär bekräftar du att du har nödvändiga tillstånd att dela dessa personuppgifter',
  subcategory: 'Underkategori',
  isRequired: 'Krävs',
  riskAssessmentEnabled: 'Aktivera riskbedömning',
  riskAssessment: 'Riskbedömning',
  yes: 'Ja',
  no: 'Nej',
  sourceOptionsSensors: 'Källalternativ (sensorer)',
  sourceDcm: 'Dcm',
  sourceProbe: 'Sond',
  availableCustomValues: 'Tillgängliga anpassade värden',
  ERR_CODE__DUPLICATE: 'Duplicera',
  ERR_CODE__DEFAULT_VALUE: 'Det finns redan ett standardvärde',
  ERR_CODE__EXISTING_FORM: 'Det finns redan ett aktivt formulär för detta fordon och typ',
  availableValues: 'Tillgängliga värden',
  pump: 'Pump',
  DanskName: 'Dansk Name',
  GermanName: 'Tyska namn',
  EnglishName: 'Engelskt namn',
  BritainName: 'Storbritannien',
  SpanishName: 'Spansknamn',
  FrenchName: 'Fransk namn',
  NederlandsName: 'Nederlands namn',
  NorskName: 'Norsknamn',
  SvenskaName: 'Svenska namn',
  ItalianName: 'Italienska namn',
  PolishName: 'Polskt namn',
  DanskNameDefault: 'DANSK Namn (standard)',
  GermanNameDefault: 'Tyska namn (standard)',
  EnglishNameDefault: 'Engelska namn (standard)',
  BritainNameDefault: 'Storbritanniens namn (standard)',
  SpanishNameDefault: 'Spanska namn (standard)',
  FrenchNameDefault: 'Franska namn (standard)',
  NederlandsNameDefault: 'Nederlands namn (standard)',
  NorskNameDefault: 'Norsk Name (standard)',
  SvenskaNameDefault: 'Svenska namn (standard)',
  ItalianNameDefault: 'Italienska namn (standard)',
  PolishNameDefault: 'Polskt namn (standard)',
  DanskDescription: 'DANSK BESKRIVNING',
  GermanDescription: 'Tysk beskrivning',
  EnglishDescription: 'Engelska beskrivning',
  BritainDescription: 'Storbritanniens beskrivning',
  SpanishDescription: 'Spansk beskrivning',
  FrenchDescription: 'Fransk beskrivning',
  NederlandsDescription: 'Nederlands Beskrivning',
  NorskDescription: 'Norsk Beskrivning',
  SvenskaDescription: 'Svenska beskrivning',
  ItalianDescription: 'Italienska beskrivning',
  PolishDescription: 'Polsk beskrivning',
  DanskDescriptionDefault: 'DANSK BESKRIVNING (Standard)',
  GermanDescriptionDefault: 'Tysk beskrivning (standard)',
  EnglishDescriptionDefault: 'Engelsk beskrivning (standard)',
  BritainDescriptionDefault: 'Storbritanniens beskrivning (standard)',
  SpanishDescriptionDefault: 'Spansk beskrivning (standard)',
  FrenchDescriptionDefault: 'Fransk beskrivning (standard)',
  NederlandsDescriptionDefault: 'Nederlands Beskrivning (standard)',
  NorskDescriptionDefault: 'Norsk Beskrivning (standard)',
  SvenskaDescriptionDefault: 'Svenska Beskrivning (standard)',
  ItalianDescriptionDefault: 'Italienska Beskrivning (standard)',
  PolishDescriptionDefault: 'Polsk beskrivning (standard)',
  DanskContent: 'Danskinnehåll',
  GermanContent: 'Tyska',
  EnglishContent: 'Engelska innehåll',
  BritainContent: 'Storbritannien innehåll',
  SpanishContent: 'Spanskt innehåll',
  FrenchContent: 'Franskt innehåll',
  NederlandsContent: 'Nederlandsinnehåll',
  NorskContent: 'Norskinnehåll',
  SvenskaContent: 'Svenska innehåll',
  ItalianContent: 'Italienska innehåll',
  PolishContent: 'Polskt innehåll',
  DanskContentDefault: 'Dansk -innehåll (standard)',
  GermanContentDefault: 'Tyskt innehåll (standard)',
  EnglishContentDefault: 'Engelska innehåll (standard)',
  BritainContentDefault: 'Storbritanniens innehåll (standard)',
  SpanishContentDefault: 'Spanskt innehåll (standard)',
  FrenchContentDefault: 'Franskt innehåll (standard)',
  NederlandsContentDefault: 'Nederlands innehåll (standard)',
  NorskContentDefault: 'Norsk -innehåll (standard)',
  SvenskaContentDefault: 'Svenska -innehåll (standard)',
  ItalianContentDefault: 'Italienska -innehåll (standard)',
  PolishContentDefault: 'Polskt -innehåll (standard)',
  DanskCategory: 'Dansk -kategori',
  GermanCategory: 'Tysk kategori',
  EnglishCategory: 'Engelska kategori',
  BritainCategory: 'Storbritanniens kategori',
  SpanishCategory: 'Spansk kategori',
  FrenchCategory: 'Fransk kategori',
  NederlandsCategory: 'Nederlands kategori',
  NorskCategory: 'Norsk kategori',
  SvenskaCategory: 'Svenska kategori',
  ItalianCategory: 'Italienska kategori',
  PolishCategory: 'Polsk kategori',
  DanskCategoryDefault: 'Dansk -kategori (standard)',
  GermanCategoryDefault: 'Tysk kategori (standard)',
  EnglishCategoryDefault: 'Engelsk kategori (standard)',
  BritainCategoryDefault: 'Storbritanniens kategori (standard)',
  SpanishCategoryDefault: 'Spansk kategori (standard)',
  FrenchCategoryDefault: 'Fransk kategori (standard)',
  NederlandsCategoryDefault: 'Nederlands kategori (standard)',
  NorskCategoryDefault: 'Norsk -kategori (standard)',
  SvenskaCategoryDefault: 'Svenska kategori (standard)',
  ItalianCategoryDefault: 'Italienska kategori (standard)',
  PolishCategoryDefault: 'Polsk kategori (standard)',
  DanskSubcategory: 'Dansk underkategori',
  GermanSubcategory: 'Underkategori',
  EnglishSubcategory: 'Underkategori',
  BritainSubcategory: 'Storbritannien underkategori',
  SpanishSubcategory: 'Underkategori',
  FrenchSubcategory: 'Underkategori',
  NederlandsSubcategory: 'Underkategori',
  NorskSubcategory: 'Norsk underkategori',
  SvenskaSubcategory: 'Svenska underkategori',
  ItalianSubcategory: 'Italienska underkategori',
  PolishSubcategory: 'Polsk underkategori',
  DanskSubcategoryDefault: 'Dansk underkategori (standard)',
  GermanSubcategoryDefault: 'Tysk underkategori (standard)',
  EnglishSubcategoryDefault: 'Engelsk underkategori (standard)',
  BritainSubcategoryDefault: 'Storbritanniens underkategori (standard)',
  SpanishSubcategoryDefault: 'Spansk underkategori (standard)',
  FrenchSubcategoryDefault: 'Fransk underkategori (standard)',
  NederlandsSubcategoryDefault: 'Nederlands underkategori (standard)',
  NorskSubcategoryDefault: 'Norsk underkategori (standard)',
  SvenskaSubcategoryDefault: 'Svenska underkategori (standard)',
  ItalianSubcategoryDefault: 'Italienska underkategori (standard)',
  PolishSubcategoryDefault: 'Polsk underkategori (standard)',
  priorSignatures: 'Tidigare underskrifter',
  damageWaiverSignature: 'Skadeavstående signatur',
  waterReleaseSignature: 'Water Release Signatur',
  errors,
  themes,
  isPDFRecipientListEnable: 'Aktiverad PDF-mottagarelista på kundsignaturskärmen',
  isRejectLoadsEnable: 'Aktivera avvisande av last efter ankomstplats',
  rejectLoad_returnedMaterial: 'Arbetsflödesknapp för returnerat material',
  rejectLoad_returnedAndFinalize: 'Arbetsflödesknappar för returnerat material och slutförande',
  driverRejection: 'Avvisa laddning',
  submitRejection: 'Skicka avvisa',

  costAndPaymentsSummary: 'Sammanfattning av kostnader och betalningar',
  subtotal: 'Delsumma',
  tax: 'Beskatta',
  remainingBalance: 'Återstående balans',
  paymentAmount: 'Betalningsbelopp',
  total: 'Total',
  paymentMethod: 'Betalningsmetod',
  paymentProducts: 'Produkter',
  unitPrice: 'Enhetspris',
  amount: 'Belopp',
  otherPaymentMethod: 'Annat betalningssätt',
  method: 'Metod',
  paymentOptions: 'Betalningsalternativ',
  typeAdditionalInfo: 'Skriv in ytterligare information här',
  COD: 'Postförskott',
  AMEX: 'American Express',
  CASH: 'Kontanter',
  CHECK: 'Kolla upp',
  'CREDIT CARD': 'Kreditkort',
  ACCOUNT: 'Öppet konto',
  UNKNOWN: 'Okänd',
  remainingMaterial: 'Återstående material',
  splitLoad: 'Dela last',
  isEnablePaymentAndCostWorkflows: 'Aktivera betalnings- och kostnadsarbetsflöden',
  airTempValue: 'Lufttemperatur',
  concreteTempValue: 'Betongtemperatur',
  cylinderNumber: 'Cylindernummer',
  materialOnTruck: 'Material på lastbil',
  waterLocation: 'Vattenplats',
  dotWorkflow: 'Batchdetaljer',
  inspectorSignature: 'Inspektörsignatur',
  producerSignature: 'Producentens signatur',
  addedItems: 'Tillagda objekt',
  producerSignatureContact: 'Producentens namn',
  inspectorSignatureContact: 'Inspektörens namn',
  waterAtPlant: 'Vatten på anläggningen (gl)',
  waterAtJobsite: 'Vatten på arbetsplatsen (gl)',
  totalActualWater: 'Totalt faktiskt vatten',
  timeDischarged: 'Tid för urladdning',
  ingredient: 'Ingrediens',
  mcFac: 'MCFac',
  absFac: 'ABSFAC',
  od: 'OD',
  abs: 'ABS',
  ssd: 'SSD',
  freeMst: 'Fri Fukt',
  cydsTarg: 'CYDS Mål',
  target: 'Mål',
  actual: 'Verklig',
  freWat: 'Fritt Vatten',
  trim: 'Trimma',
  batchInformation: 'Batchinformation',
  numberOfBatches: 'Antal Partier',
  totalWater: 'Totalt Vatten',
  designWater: 'Design Vatten',
  toAdd: 'Att Lägga Till',
  designWC: 'Design W/C',
  waterCement: 'Vatten / Cement',
  waterInTruck: 'Vatten i Lastbil',
  adjustWater: 'Justera Vatten',
  trimWater: 'Trimma Vatten',
  plantWeather: 'Väder på Anläggning',
  rh: 'RH',
  observationTime: 'Observationstid',
  address: 'Adress',
  plant: 'Anläggning',
  sp: 'S.P. #',
  beginTime: 'Starttid',
  mixDesc: 'Blandningsbeskrivning',
  qtyOder: 'Mängd Beställning',
  poTag: 'PO #',
  bridge: 'Bro #',
  volume: 'Volym',
  truckTag: 'Lastbil #',
  qtyDelivered: 'Mängd Levererad',
  qtyOrder: 'Mängd Beställning',
  job: 'Jobb #',
  batchRecord: 'Batchpost',
  mixID: 'Blandning ID',
  loads: 'Laster',
  isDot: 'Är DOT',
  tolStatus: 'TOL Status',
  lastUpdate: 'Senaste uppdatering',
  driverClickedFinalize: 'Föraren klickade på Slutför',
  autoSubmit: 'Auto Skicka',
  noTaxInformationIsAvailable: 'Ingen skatteinformation tillgänglig',
  refreshTaxData: 'Uppdatera skattedata',
};
