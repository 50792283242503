import errors from './errors';
import themes from './theme';
import features from './features';
import common from '../common';

export default {
  ...common,
  mobileTicket: 'Mobile Ticket',
  ...features,
  save: 'Guardar',
  username: 'Nombre de usuario',
  password: 'Contraseña',
  login: 'Acceso',
  required: 'Requerido',
  light: 'Luz',
  dark: 'Oscuro',
  rememberMe: 'Acuérdate de mí',
  eula: 'Eula',
  copyright: '© {Year} - Command Alkon, Inc.',
  privacyPolicy: 'política de privacidad',
  customer: 'Cliente',
  project: 'Proyecto',
  isBillable: 'Facturable',
  dispatchDateTime: 'Fecha',
  orderId: 'Orden',
  ticketId: 'Id del Ticket',
  location: 'Ubicación',
  vehicleTypeName: 'Vehículo',
  product: 'Producto',
  products: 'Producto',
  salesTax: 'Impuesto de venta',
  totalPrice: 'Total',
  extendedPrice: 'Extendido',
  destination: 'Destino',
  productId: 'Id del Producto',
  statusCode: 'Estado',
  vehicleTypeId: 'Id de tipo de vehículo',
  locationId: 'Id de ubicación',
  customerId: 'Identificación del cliente',
  projectId: 'Id del Projecto',
  dispatchTime: 'Tiempo',
  showAll: 'Mostrar todo',
  hideAll: 'Ocultar todo',
  undoChanges: 'Deshacer cambios',
  resetColumns: 'Restablecer columnas',
  displayRowCount: `Mostrando {count, plural, 
    =0 {0 filas} 
    one {# fila} 
    other {# filas} 
  }`,
  columnFilters: 'Filtros de columna',
  visible: 'Visible',
  hidden: 'Oculto',
  itemDetail: 'Detalle del artículo',
  quantity: 'Cantidad',
  quantityMaxWaterToAdd: 'Cantidad (Maxima cantidad de agua a agregar: {maxWaterQuantity})',
  unloadingMethod: 'Método de descarga',
  time: 'Tiempo',
  Time: 'Tiempo',
  eventStatus: 'Estado',
  MTQ_abbr: 'm\u00B3',
  EA_abbr: 'EA',
  LTR_abbr: 'L',
  YDQ_abbr: 'CY',
  GLL_abbr: 'GAL',
  MTQ_text: 'Metros cubicos',
  M3_text: 'Metros cubicos',
  EA_text: 'Pieza',
  LTR_text: 'Litros',
  YDQ_text: 'Patios cúbicos',
  GLL_text: 'Galón',
  ACR_text: 'Hectáreas',
  HUR_text: 'Hora',
  INH_text: 'Pulgadas',
  FOT_text: 'Pie',
  YRD_text: 'Patio',
  MMT_text: 'Milímetro',
  CMT_text: 'Centímetro',
  MTR_text: 'Metro',
  SMI_text: 'Milla (estatuto milla)',
  KMT_text: 'Kilómetro',
  INK_text: 'Pulgada cuadrada',
  FTK_text: 'Pie cuadrado',
  YDK_text: 'Yarda cuadrada',
  MIK_text: 'Milla cuadrada',
  CMK_text: 'Centímetro cuadrado',
  MTK_text: 'Metro cuadrado',
  KMK_text: 'Kilometro cuadrado',
  INQ_text: 'Pulgada cúbica',
  MMQ_text: 'Milímetro cúbico',
  FTQ_text: 'Pie cubico',
  CMQ_text: 'Centímetro cúbico',
  OZA_text: 'Onza fluida',
  PT_text: 'Medio litro',
  QT_text: 'Cuarto de galón',
  MLT_text: 'Mililitro',
  ONZ_text: 'Onza',
  LBR_text: 'Libra',
  STN_text: 'Tonelada',
  GRM_text: 'Gramo',
  KGM_text: 'Kilogramo',
  TNE_text: 'Tonelada',
  PS_text: 'PSI',
  MPA_text: 'Megapascal',
  C56_text: 'Newton por milímetro cuadrado',
  C62_text: 'Uno',
  NL_text: 'Carga',
  MT_text: 'Estera',
  P1_text: 'Por ciento',
  CEL_text: 'Grado Celsius',
  FAH_text: 'Grado Fahrenheit',
  BLL_text: 'Barril',
  CLT_text: 'Centilitro',
  DLT_text: 'Decilitro',
  GLI_text: 'Galón',
  '2U_text': 'Megagrama',
  SA_text: 'Bolsa',
  '60_text': 'Porcentaje',
  BG_text: 'Bolsa',
  SEC_text: 'Segundo',
  MIN_text: 'Minuto',
  '64_text': 'Libra por pulgada cuadrada - calibre',
  SP_text: 'Paquete de estante',
  MMK_text: 'Milímetro cuadrado',
  MTN_text: 'tonelada métrica',
  GT_text: 'Gigatoneladas',
  '/NL_text': 'Por carga',
  '/EA_text': 'Por cantidad',
  '/H/ + h_text': 'Por hora',
  '/YQ/ + YDQ_text': 'Por yarda cúbica',
  '/MQ/ + MTQ_text': 'Por metro cúbico',
  '/MN/ + MIN_text': 'Por minuto',
  '/MT_text': 'Por metro',
  DSG_text: 'Dosis',
  '/C_text': 'Por 100 pesos',
  '64_2_text': 'Específico',
  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,
  M3: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,
  LTR: `{value, plural,
    =0 {0 Litros}
    one {1 Litro}
    other {# Litros}
  }`,
  TO: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,
  ITEM: `{value, plural,
    =0 {0 Elementos}
    one {1 Elemento}
    other {# Elementos}
  }`,
  YDQ_short: `{value, plural,
    =0 {0 CY}
    one {1 CY}
    other {# CY}
  }`,
  YDQ: `{value, plural,
    =0 {0 Yardas Cubicas}
    one {1 Yarda Cubica}
    other {# Yardas Cubicas}
  }`,
  GLL: `{value, plural,
    =0 {0 Galónes}
    one {1 Galón}
    other {# Galónes}
  }`,
  EA: `{value, plural,
    =0 {0 Piezas}
    one {1 Pieza}
    other {# Piezas}
  }`,
  MIN: `{value, plural,
    =0 {0 Minutos}
    one {1 Minuto}
    other {# Minutos}
  }`,
  ACR: `{value, plural,
    =0 {0 Acres}
    one {1 Acre}
    other {# Acres}
  }`,
  HUR: `{value, plural,
    =0 {0 Horas}
    one {1 Hora}
    other {# Horas}
  }`,
  INH: `{value, plural,
    =0 {0 Pulgadas}
    one {1 Pulgada}
    other {# Pulgadas}
  }`,
  FOT: `{value, plural,
    =0 {0 Pies}
    one {1 Pie}
    other {# Pies}
  }`,
  YRD: `{value, plural,
    =0 {0 Yardas}
    one {1 Yarda}
    other {# Yardas}
  }`,
  MMT: `{value, plural,
    =0 {0 Millimeters}
    one {1 Millimeter}
    other {# Millimeters}
  }`,
  CMT: `{value, plural,
    =0 {0 Centimeters}
    one {1 Centimeter}
    other {# Centimeters}
  }`,
  MTR: `{value, plural,
    =0 {0 Metros}
    one {1 Metro}
    other {# Metros}
  }`,
  SMI: `{value, plural,
    =0 {0 Miles (Statute Miles)}
    one {1 Mile (Statute Mile)}
    other {# Miles (Statute Miles)}
  }`,
  KMT: `{value, plural,
    =0 {0 Kilometros}
    one {1 Kilometro}
    other {# Kilometros}
  }`,
  INK: `{value, plural,
    =0 {0 Pulgadas cuadradas}
    one {1 Pulgada cuadrada}
    other {# Pulgadas cuadradas}
  }`,
  FTK: `{value, plural,
    =0 {0 Pies cuadrados}
    one {1 Pie cuadrado}
    other {# Pies cuadrados}
  }`,
  YDK: `{value, plural,
    =0 {0 Yardas cuadradas}
    one {1 Yarda cuadrada}
    other {# Yardas cuadradas}
  }`,
  MIK: `{value, plural,
    =0 {0 Millas cuadradas}
    one {1 Milla cuadrada}
    other {# Millas cuadradas}
  }`,
  CMK: `{value, plural,
    =0 {0 Square Centimeters}
    one {1 Square Centimeter}
    other {# Square Centimeters}
  }`,
  MTK: `{value, plural,
    =0 {0 Metros cubicos}
    one {1 Metro cúbico}
    other {# Metros cubicos}
  }`,
  KMK: `{value, plural,
    =0 {0 Kilómetros cuadrados}
    one {1 Kilometro cuadrado}
    other {# Kilómetros cuadrados}
  }`,
  INQ: `{value, plural,
    =0 {0 Pulgadas cúbicas}
    one {1 Pulgada cúbica}
    other {# Pulgadas cúbicas}
  }`,
  MMQ: `{value, plural,
    =0 {0 Cubic Millimeters}
    one {1 Cubic Millimeter}
    other {# Cubic Millimeters}
  }`,
  FTQ: `{value, plural,
    =0 {0 Pies cúbicos}
    one {1 Pie cúbico}
    other {# Pies cúbicos}
  }`,
  CMQ: `{value, plural,
    =0 {0 Centímetros cubicos}
    one {1 Centímetro cúbico}
    other {# Centímetros cubicos}
  }`,
  OZA: `{value, plural,
    =0 {0 Fl oz}
    one {1 Onza fluida}
    other {# Fl oz}
  }`,
  PT: `{value, plural,
    =0 {0 Pintas}
    one {1 Pinta}
    other {# Pintas}
  }`,
  QT: `{value, plural,
    =0 {0 Galónes}
    one {1 Galón}
    other {# Galónes}
  }`,
  MLT: `{value, plural,
    =0 {0 Milimetros}
    one {1 Milimetro}
    other {# Milimetros}
  }`,
  ONZ: `{value, plural,
    =0 {0 Onzas}
    one {1 Onza}
    other {# Onzas}
  }`,
  LBR: `{value, plural,
    =0 {0 Libras}
    one {1 Libra}
    other {# Libras}
  }`,
  STN: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,
  GRM: `{value, plural,
    =0 {0 Gramos}
    one {1 Gramo}
    other {# Gramos}
  }`,
  KGM: `{value, plural,
    =0 {0 Kilogramos}
    one {1 Kilogramo}
    other {# Kilogramos}
  }`,
  TNE: `{value, plural,
    =0 {0 Toneladas}
    one {1 Tonelada}
    other {# Toneladas}
  }`,
  PS: `{value, plural,
    =0 {0 PSI}
    one {1 PSI}
    other {# PSI}
  }`,
  MPA: `{value, plural,
    =0 {0 Megapascales}
    one {1 Megapascal}
    other {# Megapascales}
  }`,
  C56: `{value, plural,
    =0 {0 Newtons por milímetro cuadrado}
    one {1 Newton por milímetro cuadrado}
    other {# Newtons por milímetro cuadrado}
  }`,
  C62: `{value, plural,
    =0 {0 One}
    one {1 One}
    other {# One}
  }`,
  NL: `{value, plural,
    =0 {0 Cargas}
    one {1 Carga}
    other {# Cargas}
  }`,
  MT: `{value, plural,
    =0 {0 Mats}
    one {1 Mat}
    other {# Mats}
  }`,
  P1: `{value, plural,
    =0 {0 Por ciento}
    one {1 Por ciento}
    other {# Por ciento}
  }`,
  CEL: `{value, plural,
    =0 {0 Grados Celsius}
    one {1 Grado Celsius}
    other {# Grados Celsius}
  }`,
  FAH: `{value, plural,
    =0 {0 Grados Fahrenheit}
    one {1 Grado Fahrenheit}
    other {# Grados Fahrenheit}
  }`,
  BLL: `{value, plural,
    =0 {0 Barriles}
    one {1 Barril}
    other {# Barriles}
  }`,
  CLT: `{value, plural,
    =0 {0 Centilitros}
    one {1 Centilitro}
    other {# Centilitros}
  }`,
  DLT: `{value, plural,
    =0 {0 Decilitros}
    one {1 Decilitro}
    other {# Decilitros}
  }`,
  GLI: `{value, plural,
    =0 {0 Galones}
    one {1 Galon}
    other {# Galones}
  }`,
  '2U': `{value, plural,
    =0 {0 Megagramas}
    one {1 Megagrama}
    other {# Megagramas}
  }`,
  SA: `{value, plural,
    =0 {0 Sacos}
    one {1 Saco}
    other {# Sacos}
  }`,
  60: `{value, plural,
    =0 {0 Porcentaje}
    one {1 Porcentaje}
    other {# Porcentaje}
  }`,
  BG: `{value, plural,
    =0 {0 Bolsas}
    one {1 Bolsa}
    other {# Bolsas}
  }`,
  SEC: `{value, plural,
    =0 {0 Segundos}
    one {1 Segundo}
    other {# Segundos}
  }`,
  64: `{value, plural,
    =0 {0 Libras por pulgada cuadrada - calibre}
    one {1 Libra por pulgada cuadrada - calibre}
    other {# Libras por pulgada cuadrada - calibre}
  }`,
  SP: `{value, plural,
    =0 {0 Paquetes de estante}
    one {1 Paquete de estante}
    other {# Paquetes de estante}
  }`,
  MMK: `{value, plural,
    =0 {0 milímetros cuadrados}
    one {1 milímetro cuadrado}
    other {# milímetros cuadrados}
  }`,
  MTN: `{value, plural,
    =0 {0 toneladas métricas}
    one {1 tonelada métrica}
    other {# toneladas métricas}
  }`,
  GT: `{value, plural,
    =0 {0 gigatoneladas}
    one {1 gigatonelada}
    other {# gigatoneladas}
  }`,
  '/NL': `{value, plural,
    =0 {0 por carga}
    one {1 por carga}
    other {# por carga}
  }`,
  '/EA': `{value, plural,
    =0 {0 por cantidad}
    one {1 por cantidad}
    other {# por cantidad}
  }`,
  '/H/ + h': `{value, plural,
    =0 {0 por hora}
    one {1 por hora}
    other {# por hora}
  }`,
  '/YQ/ + YDQ': `{value, plural,
    =0 {0 por yarda cúbica}
    one {1 por yarda cúbica}
    other {# por yarda cúbica}
  }`,
  '/MQ/ + MTQ': `{value, plural,
    =0 {0 por metro cúbico}
    one {1 por metro cúbico}
    other {# por metro cúbico}
  }`,
  '/MN/ + MIN': `{value, plural,
    =0 {0 por minuto}
    one {1 por minuto}
    other {# por minuto}
  }`,
  '/MT': `{value, plural,
    =0 {0 por metro}
    one {1 por metro}
    other {# por metro}
  }`,
  DSG: `{value, plural,
    =0 {0 Dosis}
    one {1 Dosis}
    other {# Dosis}
  }`,
  '/C': `{value, plural,
    =0 {0 por 100 peso}
    one {1 por 100 peso}
    other {# por 100 peso}
  }`,
  '64_2': `{value, plural,
    =0 {0 Específico}
    one {1 Específico}
    other {# Específico}
  }`,
  LTR_short: `{value, plural,
  =0 {0 L}
  one {1 L}
  other {# L}
}`,
  TO_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# Tn}
}`,
  ITEM_short: `{value, plural,
  =0 {0 Items}
  one {1 Item}
  other {# Items}
}`,
  GLL_short: `{value, plural,
  =0 {0 GAL}
  one {1 GAL}
  other {# GAL}
}`,
  EA_short: `{value, plural,
  =0 {0 EA}
  one {1 EA}
  other {# EA}
}`,
  MIN_short: `{value, plural,
  =0 {0 MIN}
  one {1 MIN}
  other {# MIN}
}`,
  ACR_short: `{value, plural,
  =0 {0 AC}
  one {1 AC}
  other {# AC}
}`,
  HUR_short: `{value, plural,
  =0 {0 HR}
  one {1 HR}
  other {# HR}
}`,
  INH_short: `{value, plural,
  =0 {0 IN}
  one {1 IN}
  other {# IN}
}`,
  FOT_short: `{value, plural,
  =0 {0 FT}
  one {1 FT}
  other {# FT}
}`,
  YRD_short: `{value, plural,
  =0 {0 YD}
  one {1 YD}
  other {# YD}
}`,
  MMT_short: `{value, plural,
  =0 {0 MM}
  one {1 MM}
  other {# MM}
}`,
  CMT_short: `{value, plural,
  =0 {0 CM}
  one {1 CM}
  other {# CM}
}`,
  MTR_short: `{value, plural,
  =0 {0 M}
  one {1 M}
  other {# M}
}`,
  SMI_short: `{value, plural,
  =0 {0 Miles (ST MI)}
  one {1 Mile ((ST MI)}
  other {# Miles ((ST MI)}
}`,
  KMT_short: `{value, plural,
  =0 {0 KM}
  one {1 KM}
  other {# KM}
}`,
  INK_short: `{value, plural,
  =0 {0 SQ IN}
  one {1 SQ IN}
  other {# SQ IN}
}`,
  FTK_short: `{value, plural,
  =0 {0 FT2}
  one {1 FT2}
  other {# FT2}
}`,
  YDK_short: `{value, plural,
  =0 {0 SQ YD}
  one {1 SQ YD}
  other {# SQ YD}
}`,
  MIK_short: `{value, plural,
  =0 {0 SQ MI}
  one {1 SQ MI}
  other {# SQ MI}
}`,
  CMK_short: `{value, plural,
  =0 {0 CM2}
  one {1 CM2}
  other {# CM2}
}`,
  MTK_short: `{value, plural,
  =0 {0 M3}
  one {1 M3}
  other {# M3}
}`,
  KMK_short: `{value, plural,
  =0 {0 KM2}
  one {1 KM2}
  other {# KM2}
}`,
  INQ_short: `{value, plural,
  =0 {0 CU IN}
  one {1 CU IN}
  other {# CU IN}
}`,
  MMQ_short: `{value, plural,
  =0 {0 MM3}
  one {1 MM3}
  other {# MM3}
}`,
  FTQ_short: `{value, plural,
  =0 {0 FT3}
  one {1 FT3}
  other {# FT3}
}`,
  CMQ_short: `{value, plural,
  =0 {0 CM3}
  one {1 CM3}
  other {# CM3}
}`,
  OZA_short: `{value, plural,
  =0 {0 FL OZ}
  one {1 FL OZ}
  other {# FL OZ}
}`,
  PT_short: `{value, plural,
  =0 {0 PT}
  one {1 PT}
  other {# PT}
}`,
  QT_short: `{value, plural,
  =0 {0 QT}
  one {1 QT}
  other {# QT}
}`,
  MLT_short: `{value, plural,
  =0 {0 mL}
  one {1 mL}
  other {# mL}
}`,
  ONZ_short: `{value, plural,
  =0 {0 OZ}
  one {1 OZ}
  other {# OZ}
}`,
  LBR_short: `{value, plural,
  =0 {0 LB}
  one {1 LB}
  other {# LB}
}`,
  STN_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# TN}
}`,
  GRM_short: `{value, plural,
  =0 {0 G}
  one {1 G}
  other {# G}
}`,
  KGM_short: `{value, plural,
  =0 {0 KG}
  one {1 KG}
  other {# KG}
}`,
  TNE_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# TN}
}`,
  PS_short: `{value, plural,
  =0 {0 PSI}
  one {1 PSI}
  other {# PSI}
}`,
  MPA_short: `{value, plural,
  =0 {0 MPa}
  one {1 MPa}
  other {# MPa}
}`,
  C56_short: `{value, plural,
    =0 {0 Newtons por milímetro cuadrado}
    one {1 Newton por milímetro cuadrado}
    other {# Newtons por milímetro cuadrado}
}`,
  C62_short: `{value, plural,
  =0 {0 One}
  one {1 One}
  other {# One}
}`,
  NL_short: `{value, plural,
  =0 {0 Loads}
  one {1 Load}
  other {# Loads}
}`,
  MT_short: `{value, plural,
  =0 {0 Mats}
  one {1 Mat}
  other {# Mats}
}`,
  P1_short: `{value, plural,
  =0 {0 %}
  one {1 %}
  other {# %}
}`,
  CEL_short: `{value, plural,
  =0 {0 C}
  one {1 C}
  other {# C}
}`,
  FAH_short: `{value, plural,
  =0 {0 F}
  one {1 F}
  other {# F}
}`,
  BLL_short: `{value, plural,
  =0 {0 BBL}
  one {1 BBL}
  other {# BBL}
}`,
  CLT_short: `{value, plural,
  =0 {0 cL}
  one {1 cL}
  other {# cL}
}`,
  DLT_short: `{value, plural,
  =0 {0 dL}
  one {1 dL}
  other {# dL}
}`,
  GLI_short: `{value, plural,
  =0 {0 GAL}
  one {1 GAL}
  other {# GAL}
}`,
  '2U_short': `{value, plural,
    =0 {0 Megagramas}
    one {1 Megagrama}
    other {# Megagramas}
}`,
  SA_short: `{value, plural,
    =0 {0 Sacos}
    one {1 Saco}
    other {# Sacos}
}`,
  '60_short': `{value, plural,
    =0 {0 Porcentaje}
    one {1 Porcentaje}
    other {# Porcentaje}
}`,
  BG_short: `{value, plural,
    =0 {0 Bolsas}
    one {1 Bolsa}
    other {# Bolsas}
}`,
  SEC_short: `{value, plural,
  =0 {0 SEC}
  one {1 SEC}
  other {# SEC}
}`,
  '64_short': `{value, plural,
    =0 {0 Libras por pulgada cuadrada - calibre}
    one {1 Libra por pulgada cuadrada - calibre}
    other {# Libras por pulgada cuadrada - calibre}
}`,
  SP_short: `{value, plural,
    =0 {0 Paquetes de estante}
    one {1 Paquete de estante}
    other {# Paquetes de estante}
}`,
  MMK_short: `{value, plural,
    =0 {0 milímetros cuadrados}
    one {1 milímetro cuadrado}
    other {# milímetros cuadrados}
}`,
  MTN_short: `{value, plural,
  =0 {0 MT}
  one {1 MT}
  other {# MT}
}`,
  GT_short: `{value, plural,
  =0 {0 GT}
  one {1 GT}
  other {# GT}
}`,
  '/NL_short': `{value, plural,
=0 {0 /Load}
one {1 /Load}
other {# /Load}
}`,
  '/EA_short': `{value, plural,
=0 {0 /EA}
one {1 /EA}
other {# /EA}
}`,
  '/H/ + h_short': `{value, plural,
=0 {0 /Hour}
one {1 /Hour}
other {# /Hour}
}`,
  '/YQ/ + YDQ_short': `{value, plural,
=0 {0 /CY}
one {1 /CY}
other {# /CY}
}`,
  '/MQ/ + MTQ_short': `{value, plural,
=0 {0 /M3}
one {1 /M3}
other {# /M3}
}`,
  '/MN/ + MIN_short': `{value, plural,
=0 {0 /MIN}
one {1 /MIN}
other {# /MIN}
}`,
  '/MT_short': `{value, plural,
=0 {0 /M}
one {1 /M}
other {# /M}
}`,
  DSG_short: `{value, plural,
=0 {0 Dósis}
one {1 Dósis}
other {# Dósis}
}`,
  '/C_short': `{value, plural,
=0 {0 /C}
one {1 /C}
other {# /C}
}`,
  '64_2_short': `{value, plural,
=0 {0 Específico}
one {1 Específico}
other {# Específico}
}`,
  mixIdLabel: 'Id de mezcla/descripción',
  orderedQtyLabel: 'Cantidad ordenada',
  loadedQtyLabel: 'Cantidad cargada',
  addedByCustomer: 'Agregado por el cliente',
  PRINTED: 'Impreso',
  LOADING_STARTED: 'Inicio de carga',
  LOADING_COMPLETE: 'Finalizacion de cargar',
  TO_JOB: 'Abandono la planta',
  ARRIVE_JOB: 'En el sitio de trabajo',
  UNLOADING: 'Inicio de descarga',
  END_UNLOADING: 'Finalizacion de la descarga',
  LEAVE_JOB: 'Deja el trabajo',
  IN_YARD: 'En el patio',
  PUMPING_STARTED: 'Inicio de Bombeo',
  PUMPING_COMPLETE: 'Finalizacion de Bombeo',
  READY_TO_PUMP: 'Listo para bombear',
  FIRST_WCCONTACT: 'Primer contacto del cemento con el agua',
  plantName: 'Nombre de planta',
  plantAddress: 'Dirección de la planta',
  loadingScheduleStart: 'Tiempo de carga programado',
  deliveryAddress: 'Dirección de entrega',
  phoneNumber: 'Número de teléfono',
  unloadingScheduleStart: 'Tiempo de descarga planeado (ETA)',
  agreedUnloading: 'Tiempos de descarga ordenados',
  deliveryInformation: 'información de entrega',
  next: 'Próximo',
  material: 'Material',
  loadingPoint: 'Punto de carga',
  unloadingPoint: 'Punto de descarga',
  comment: 'Comentario',
  sapDeliveryNumber: 'Número de entrega de SAP (número BCC)',
  salesOrderNumber: 'Número de pedido de venta y línea de artículos',
  wcDateTime: 'Fecha y hora con contacto con',
  po: 'numero postal',
  orderedByName: 'Orden realizado por nombre',
  orderedByPhone: 'Pedido realizado por número de teléfono',
  resourceId: 'Id del recurso',
  totalLoadedQty: 'Cantidad cargada total, incluida esta carga',
  spacing: 'Espacio de carga',
  deliveryFlow: 'Flujo de entrega',
  timeOnSite: 'Tiempo total en el sitio/Tiempo de descarga ordenado',
  salesText: 'Texto de ventas (Id del material)',
  strengthClass: 'Clase de fuerza',
  environmentExposure: 'Exposición al medio ambiente',
  metExposureClasses: 'Clases de exposición cumplidas',
  dmax: 'Dmax',
  standardCert: 'Estándar (certificación)',
  workabilityTarget: 'Objetivo de trabajo del cliente',
  stoneQuality: 'Calidad de piedra',
  sandQuality: 'Calidad de arena',
  targetWC: 'Relación objetivo W/C de la receta',
  controlClass: 'Clase de control',
  chlorideClass: 'Clase de cloruro',
  typeAndStrength1: 'Tipo de cemento y clase de fuerza 1',
  typeAndStrength2: 'Tipo de cemento y clase de fuerza 2',
  typeOfAddition: 'Tipo de adición',
  typeOfAdmixture: 'Tipo de mezcla',
  orderedTemp: 'Temperatura ordenada',
  fibers1: 'Tipo y contenido de fibras 1',
  fibers2: 'Tipo y contenido de fibras 2',
  calculatedMaturityTime: 'Tiempo de vencimiento calculado desde el horario de contacto w/c',
  mixIdentity: 'Mezclar identidad',
  grade: 'Calificación',
  mixType: 'Tipo de mezcla',
  maxAggSize: 'Tamaño máximo de agg',
  maxAggType: 'Tipo de AGG máximo',
  cementType: 'Tipo de cemento',
  admixtures: 'Ingredientes',
  addmixDescription: 'Descripción del anuncio',
  slump: 'Depresión',
  minCementContent: 'Contenido de cemento mínimo',
  maxWatCemRatio: 'Relación de agua/cemento máxima',
  maxWaterToAdd: 'Max Agua para agregar',
  mortarMinWorkingLife: 'Vida laboral de mortero min',
  code: 'Código',
  dcClass: 'dcclass',
  chloride: 'Cloruro',
  signedByHanson: 'Firmado por Hanson',
  warmStamp: 'Sello cálido',
  coldStamp: 'Estampilla',
  containsSikatard: 'Contiene sikatard',
  containsDelvo: 'Contiene Delvo',
  generalDelivery: 'Información general de entrega',
  materialInfo: 'Material de información',
  water: 'Agua',
  materialService: 'Material/Servicio',
  returnedMaterial: 'Material devuelto',
  returnedConcrete: 'Hormigón devuelto',
  waterAdded: 'Agua agregada',
  concreteOnTruck: 'Concreto en camión',
  concreteOnTruckUom: 'Concreto en camión uom (predeterminado)',
  reason: 'Razón',
  customerAddedWater: 'El cliente agregó agua',
  addWater: 'Agregar agua',
  addedAt: 'Agregado en',
  addMaterialService: 'Agregar material/servicio',
  customerAddedProduct: 'Producto agregado del cliente',
  addReturnedMaterial: 'Agregar material devuelto',
  leftover: 'Sobrante',
  submitAcceptance: 'Enviar aceptación',
  accepted: 'Aceptado',
  rejected: 'Rechazado',
  customerAcceptance: 'Aceptación',
  finalize: 'Finalizar',
  materialSummary: 'Resumen de material',
  mix: 'Mezcla',
  ordered: 'Ordenado',
  loaded: 'Carga de entrega',
  totalOrdered: 'Cantidad de pedido total',
  loadedOrderQuantity: 'Cantidad de pedido cargado',
  loadedOrder: 'Orden cargado',
  driverComment: 'Comentario del conductor',
  customerComment: 'Comentario del cliente',
  editComment: 'Editar comentario',
  addComment: 'Agregar comentario',
  additionalInfo: 'Información adicional',
  onBoard: 'A bordo',
  driver: 'Conductor',
  commentInstruction: 'Instrucción de comentarios',
  signatureInstruction: 'Firma del cliente',
  customerSignature: 'Firma del cliente',
  general: 'General',
  status: 'Estado',
  updateStatusTime: 'Actualizar el tiempo de estado',
  delete: 'Borrar',
  cancel: 'Cancelar',
  back: 'Atrás',
  add: 'Agregar',
  done: 'Aceptar',
  ok: 'DE ACUERDO',
  decline: 'Rechazar',
  clear: 'Limpiar',
  Submit: 'Aceptar',
  submit: 'Aceptar',
  lastRefresh: 'Última actualización',
  refreshEnabled: 'Habilitar actualizar',
  id: 'Identificación',
  name: 'Nombre',
  invoiceable: 'Facturable',
  provideSignature: 'Proporcionar firma',
  ticket: 'Ticket',
  details: 'Detalles',
  qrCode: 'Código QR',
  driverAcceptanceReason: 'Razón',
  driverAcceptance: 'Aceptación del conductor',
  'payment-method': 'Métodos de pago',
  'add-payment-method': 'Añadir método de pago',
  'reason-code': 'Código de razón',
  'add-reason-code': 'Agregar código de razón',
  'qr-code': 'Código QR',
  'unloading-method': 'Métodos de descarga',
  'add-unloading-method': 'Agregar método de descarga',
  'driver-comment': 'Comentarios del conductor',
  'add-driver-comment': 'Agregar comentario del conductor',
  'driver-sellable': 'Conductor vendible',
  vehicle: 'Vehículos',
  'add-vehicle': 'Agregar vehículo',
  'vehicle-type': 'Tipos de vehículos',
  vehicleTypes: 'Tipos de vehículos',
  vehicleType: 'tipo de vehiculo',
  'add-vehicle-type': 'Agregar tipo de vehículo',
  countries: 'Países',
  country: 'País',
  type: 'Tipo',
  uom: 'Uom',
  mandatory: 'Obligatorio',
  qrTemplate: 'Plantilla de Código QR',
  includeMixInfo: 'Incluir información de mezcla en el Código QR',
  includeTicketEvents: 'Incluir eventos de tickets en el Código QR',
  quantityField: 'Habilitar campo de cantidad',
  concreteOnTruckField: 'Habilitar concreto en el campo de camiones',
  materialOnTruckField: 'Habilitar campo de material en camiones',
  timeField: 'Habilitar campo de tiempo',
  reasonField: 'Habilitar campo de razón',
  driverInstructions: 'Instrucciones del conductor',
  waitingForTicket: 'Esperando el ticket...',
  createTestTicketForTruck: 'Crear ticket de prueba para camión',
  exceedsLoadedQuantity: 'El valor no puede exceder la cantidad cargada de {loadedQuantity}.',
  maxQuantity: 'Máxima cantidad',
  maxQuantityError: 'La cantidad no puede exceder la cantidad máxima definida de {maxQty}',
  maxValueError: 'El valor no puede exceder el máximo definido de {maxQty}',
  minValueError: 'El valor no puede ser menor que el mínimo definido de {minQty}',
  notAuthorized: 'No autorizado',
  enterTruckNumber: 'Ingrese el número de camión',
  setTruckNumber: 'Establecer número de camión',
  commentList: 'Lista de comentarios',
  list: 'Lista',
  gridPreferences: 'Preferencias de cuadrícula',
  loadingUnloadingPoints: 'Puntos de carga/descarga',
  activity: 'Actividad',
  crn: 'Crn',
  update: 'Actualizar',
  CONCRETE: 'Concreto',
  WATER: 'Agua',
  ADDITIVE: 'Aditivo',
  'submit-signature-button': 'Aceptar',
  'cancel-signature-button': 'Cancelar',
  uomCode: 'Uom predeterminado',
  'product-type': 'Tipos de productos',
  'add-product-type': 'Agregar tipo de producto',
  'add-product': 'Agregar producto',
  'setup_driver-comment': 'Comentarios del conductor',
  setup_product: 'Productos',
  'setup_product-type': 'Tipos de productos',
  'setup_qr-code': 'Código QR',
  'setup_reason-code': 'Código de razón',
  'setup_unloading-method': 'Métodos de descarga',
  'setup_entity-mt': 'Compañía',
  setup_vehicle: 'Vehículos',
  'setup_vehicle-type': 'Tipos de vehículos',
  'setup_data-retentions': 'Retención de datos',
  'add-data-retentions': 'Agregar retención de datos',
  'data-retentions': 'Retención de datos',
  setup_carriers: 'Operador',
  'add-carriers': 'Agregar operador',
  carriers: 'Operador',
  'setup_concrete-classes': 'Cumplimiento material',
  'add-concrete-classes': 'Agregar cumplimiento de material',
  'concrete-classes': 'Cumplimiento material',
  'setup_forms-and-waivers': 'Formularios y exenciones',
  'add-forms-and-waivers': 'Agregar formularios y exenciones',
  'forms-and-waivers': 'Formularios y exenciones',
  companyFleet: 'Flota de la empresa',
  category: 'Categoría',
  interval: 'Intervalo',
  orders: 'Órdenes y ticket',
  '30days': '30 dias',
  '60days': '60 días',
  '90days': '90 dias',
  '1year': '1 año',
  '3years': '3 años',
  waterMandatory: 'Obligatorio de agua',
  qrCodeTemplate: 'Plantilla de código QR',
  waterConfig: 'Campos opcionales',
  waterProduct: 'Agua',
  waterProductType: 'Tipo de producto de agua',
  defaultWaterProduct: 'Producto de agua predeterminado',
  invalidTemplate: 'Plantilla no válida',
  siteAddress: 'Dirección del sitio',
  siteContactName: 'Nombre de contacto del sitio',
  siteContactPhoneNumber: 'Número de teléfono de contacto del sitio',
  'clear-signature-button': 'Limpiar Firma',
  customerCommentEnabled: 'Habilitar el comentario del cliente',
  driverCommentEnabled: 'Habilitar el comentario del conductor',
  driverCommentMaxCharacters: 'Longitud máxima',
  customerCommentMaxCharacters: 'Longitud máxima',
  timeZone: 'Zona horaria',
  driverDidNotAdd: 'El conductor no agregó',
  maxLineItems: 'Número máximo de líneas de pedido',
  oneLineItem: 'Debe tener al menos 1 item de líne',
  signatureSiteContact: 'Nombre del cliente',
  ticketEvents: 'Eventos del ticket',
  hideEvent: 'Evento Oculto',
  editable: 'Editable',
  ticketDetail: `Ticket #{value}`,
  statusTimes: 'Tiempos de estado',
  materialAndDeliveryInfo: 'Información de material y entrega',
  customerRejection: 'Rechazo',
  'submit-rejection-button': 'Enviar rechazo',
  accept: 'Aceptar',
  reject: 'Rechazar Carga',
  signature: 'Firma',
  'no-signature': 'Sin firma',
  unFinalizePassword: 'Contraseña no finalizar',
  waterInWorkflow: 'Habilitar el paso de agua en el flujo de trabajo',
  multicountry: 'Múltiple',
  timestampAfterSequence: 'Advertencia: el tiempo no debe ser antes de la marca de tiempo anterior',
  timestampBeforeSequence: 'Advertencia: el tiempo no debe ser después de la próxima marca de tiempo',
  noRestQuantity: 'Sin material devuelto',
  returnedMaterialMandatory: 'Debe interactuar con el paso de material devuelto',
  driverCommentMaxChars: 'La longitud no puede exceder el máximo de los caracteres {maxLength}.',
  QrCodeEnabled: 'Habilitar el Código QR',
  QrMethod_linkBased: 'Basado en url',
  QrMethod_contentBased:
    'Basado en contenido, NOTA: Los códigos QR basados en contenido tienen limitaciones de caracteres que pueden causar problemas más allá de 1024 caracteres.',
  QrMethod_contentBasedWarning:
    'NOTA: Este código QR tiene {amount} caracteres. puede ser que algunos teléfonos no puedan leer este codigo.',
  returnedMaterialInWorkflow: 'Habilitar el paso de material devuelto en el flujo de trabajo',
  materialServiceInWorkflow: 'Habilitar el paso de material/servicio en el flujo de trabajo',
  totalPumpedQuantity: 'Cantidad total bombeada',
  concrete: 'Concreto',
  materialComplianceEnabled: 'Habilitar el cumplimiento del material',
  pumpStatusTimes: 'Tiempos de estado de la bomba',
  pollingInterval: 'Intervalo de votación (segundos)',
  tenSecs: 'El intervalo debe ser al menos 10 segundos',
  materialCompliance: 'Cumplimiento material',
  consistenceValue: 'Consistencia - Depresión',
  consistenceIsCompliant: 'La consistencia cumple con BS8500-2',
  airContentValue: 'Contenido aéreo',
  airContentIsCompliant: 'El contenido aéreo cumple con BS8500-2',
  consistenceIsCompliantShort: 'La consistencia cumple',
  airContentIsCompliantShort: 'El contenido aéreo cumple',
  compliance: 'Cumplimiento material de BS8500-2',
  termsAndConditionsEnabled: 'Habilitar el formulario de términos y condiciones',
  termsAndConditions: 'Términos y condiciones',
  damageWaiverEnabled: 'Habilitar el formulario de exención de daños',
  damageWaiver: 'Exención de daños',
  damageWaiverAcknowledged: 'Renuncia de daños informada',
  dynamicWaiver: 'Exención dinámica',
  dynamicWaiverEnabled: 'Habilitar la forma de exención dinámica',
  ticketListEnabled: 'Habilitar la lista de tickets',
  clearTicketAfterSubmission: 'Borbeando el ticket después del envío',
  keepTicketOpenAfterSubmission: 'Mantenga el ticket abierto después del envío',
  displayTicketList: 'Mostrar lista de tickets',
  supportedLanguages: 'Idiomas compatibles',
  allow: 'Permitir',
  default: 'Por defecto',
  isCertificationLossTriggerForReason: 'Razón de la nota de pérdida de certificación',
  isCertificationLossTriggerForProduct: 'Producto de nota de pérdida de certificación',
  translations: 'Traducciones',
  family_name: 'Apellido',
  given_name: 'Nombre de pila',
  email: 'Correo electrónico',
  role: 'Role',
  inviteUser: 'Invitar usuario',
  carrier: 'Transportador',
  emailOrPhoneNumber: 'Correo electrónico',
  usersEditor: 'Editor de usuarios',
  verifyPassword: 'Verificar contraseña',
  verificationCode: 'Código de verificación',
  register: 'Registro',
  registerInstructions: 'Complete este formulario para finalizar su registro.',
  'password-requirements':
    'Su contraseña debe tener al menos 8 caracteres, contener al menos 1 mayúscula, 1 minúscula, 1 número y 1 carácter especial.',
  passwordMinCharacters: 'La contraseña debe contener 8 o más caracteres.',
  passwordMaxCharacters: 'La contraseña no puede contener más de 99 caracteres.',
  passwordLowerCase: 'La contraseña debe incluir 1 o más carta de minúsculas.',
  passwordUpperCase: 'La contraseña debe incluir 1 o más letras de mayúsculas y mayores.',
  passwordNumber: 'La contraseña debe incluir 1 o más carácter numérico.',
  passwordSpecialCharacter: 'La contraseña debe incluir 1 o más carácter especial.',
  passwordsDontMatch: '¡Las contraseñas no coinciden!',
  success: '¡Éxito!',
  'registration-success': 'Su registro fue exitoso.',
  'post-registration-instructions': 'Ha completado el proceso de registro. Ahora puede iniciar sesión.',
  createDate: 'Fecha de Creación',
  customerPo: 'Número de PO del cliente',
  specification: 'Especificación',
  description: 'Descripción',
  classType: 'Tipo de clase',
  minimumValue: 'Valor mínimo',
  maximumValue: 'Valor máximo',
  TrackIt: 'Rastrealo',
  trackIt3PContext: 'Contexto de trackit 3p',
  inviteLanguage: 'Invitar el lenguaje',
  truckNumber: 'Numero de camion',
  adverseTemperature:
    'La temperatura ambiente al momento de la mezcla fue tal que no podemos garantizar que el concreto esté en los rangos de temperatura de 5-35oC como se especifica en EN 206.',
  environmentalNote:
    'Esta carga contiene un agente ambiental que no excede el 0.03% en peso del concreto. El agua que contiene este material para la fabricación de concreto cumplirá con BS EN 1008.',
  unloadingMethodEnabled: 'Habilitar el método de descarga',
  warrantyVoided: 'Garantía de material anulado',
  materialWarrantyVoidedEnabled:
    'Cuando se utilice la nota de pérdida de certificación o producto y código de razón, muestre una nota de pérdida de certificación al momento de la firma.',
  'setup_templates-mt': 'Plantillas',
  copyFrom: 'Copiar desde...',
  copy: 'Copiar',
  users: 'Usuarios',
  setup: 'Configuración',
  driverExperience: 'Experiencia del conductor',
  ticketList: 'Lista de tickets',
  content: 'Contenido',
  WARNINGS: 'Advertencias',
  TERMS: 'Términos',
  DAMAGE: 'Daño',
  ADDITIONALWAIVER: 'Exención adicional',
  DYNAMICWAIVER: 'Exención dinámica',
  source: 'Fuente',
  sensorUnable: 'La sonda no puede determinar la cantidad sobrante.',
  PROBE: 'Sonda',
  DRIVER: 'Conductor',
  DRS: 'DRS',
  waterReleaseFormEnabled: 'Habilitar la forma de liberación de agua',
  waterRelease: 'Liberación de agua',
  waterReleaseAcknowledged: 'Liberación de agua informada',
  'assessment-risk': 'Evaluación de riesgos',
  'setup_assessment-risk': 'Evaluación de riesgos',
  'add-assessment-risk': 'Agregar evaluación de riesgos',
  enterRecipientsButton: 'Ingresar destinatario(s)',
  recipientsInstructions: 'Ingrese el correo electrónico, separado por una coma',
  enter: 'Ingresar',
  invalidEmailAddress: 'dirección de correo electrónico no válida',
  personalDataPolicy:
    'Al ingresar datos personales en este formulario, usted afirma que tiene los permisos necesarios para compartir esos datos personales.',
  subcategory: 'Subcategoría',
  isRequired: 'Se requiere',
  riskAssessmentEnabled: 'Habilitar la evaluación de riesgos',
  riskAssessment: 'Evaluación de riesgos',
  yes: 'Sí',
  no: 'No',
  sourceOptionsSensors: 'Opciones de origen (sensores)',
  sourceDcm: 'DCM',
  sourceProbe: 'Sonda',
  availableCustomValues: 'Valores personalizados disponibles',
  ERR_CODE__DUPLICATE: 'Duplicar',
  ERR_CODE__DEFAULT_VALUE: 'Ya hay un valor predeterminado',
  ERR_CODE__EXISTING_FORM: 'Ya hay una forma activa para este vehículo y tipo.',
  availableValues: 'Valores disponibles',
  pump: 'Bomba',
  DanskName: 'Nombre en danes',
  GermanName: 'Nombre en alemán',
  EnglishName: 'Nombre en inglés',
  BritainName: 'Nombre en inglés Gran Bretaña',
  SpanishName: 'Nombre en español',
  FrenchName: 'Nombre en francés',
  NederlandsName: 'Nombre en holandés',
  NorskName: 'Nombre en noruego',
  SvenskaName: 'Nombre en sueco',
  ItalianName: 'Nombre en italiano',
  PolishName: 'Nombre en polaco',
  DanskNameDefault: 'Nombre en danés (predeterminado)',
  GermanNameDefault: 'Nombre en alemán (predeterminado)',
  EnglishNameDefault: 'Nombre en inglés (predeterminado)',
  BritainNameDefault: 'Nombre de inglés de Gran Bretaña (predeterminado)',
  SpanishNameDefault: 'Nombre en español (predeterminado)',
  FrenchNameDefault: 'Nombre en francés (predeterminado)',
  NederlandsNameDefault: 'Nombre en holandés (predeterminado)',
  NorskNameDefault: 'Nombre en noruego (predeterminado)',
  SvenskaNameDefault: 'Nombre en sueco (predeterminado)',
  ItalianNameDefault: 'Nombre en italiano (predeterminado)',
  PolishNameDefault: 'Nombre en polaco (predeterminado)',
  DanskDescription: 'Descripción en danes',
  GermanDescription: 'Descripción en alemán',
  EnglishDescription: 'Descripción en inglés',
  BritainDescription: 'Descripción de ingles de Gran Bretaña',
  SpanishDescription: 'Descripción en español',
  FrenchDescription: 'Descripción en francés',
  NederlandsDescription: 'Descripción en holandés',
  NorskDescription: 'Descripción en noruego',
  SvenskaDescription: 'Descripción en sueco',
  ItalianDescription: 'Descripción en italiano',
  PolishDescription: 'Descripción en polaco',
  DanskDescriptionDefault: 'Descripción en danés (predeterminado)',
  GermanDescriptionDefault: 'Descripción en alemén (predeterminado)',
  EnglishDescriptionDefault: 'Descripción en inglés (predeterminado)',
  BritainDescriptionDefault: 'Descripción de ingles de Gran Bretaña (predeterminado)',
  SpanishDescriptionDefault: 'Descripción en español (predeterminado)',
  FrenchDescriptionDefault: 'Descripción en francés (predeterminado)',
  NederlandsDescriptionDefault: 'Descripción en holandés (predeterminado)',
  NorskDescriptionDefault: 'Descripción en noruego (predeterminado)',
  SvenskaDescriptionDefault: 'Descripción en sueco (predeterminado)',
  ItalianDescriptionDefault: 'Descripción en italiano (predeterminado)',
  PolishDescriptionDefault: 'Descripción en polaco (predeterminado)',
  DanskContent: 'Contenido en danés',
  GermanContent: 'Contenido en alemán',
  EnglishContent: 'Contenido en inglés',
  BritainContent: 'Contenido en inglés de Gran Bretaña',
  SpanishContent: 'Contenido en español',
  FrenchContent: 'Contenido en francés',
  NederlandsContent: 'Contenido en noruego',
  NorskContent: 'Contenido en noruego',
  SvenskaContent: 'Contenido en sueco',
  ItalianContent: 'Contenido en italiano',
  PolishContent: 'Contenido en polaco',
  DanskContentDefault: 'Contenido en danés (predeterminado)',
  GermanContentDefault: 'Contenido en alemán (predeterminado)',
  EnglishContentDefault: 'Contenido en inglés (predeterminado)',
  BritainContentDefault: 'Contenido en inglés de Gran Bretaña (predeterminado)',
  SpanishContentDefault: 'Contenido en español (predeterminado)',
  FrenchContentDefault: 'Contenido en francés (predeterminado)',
  NederlandsContentDefault: 'Contenido en holandés (predeterminado)',
  NorskContentDefault: 'Contenido en noruego (predeterminado)',
  SvenskaContentDefault: 'Contenido en sueco (predeterminado)',
  ItalianContentDefault: 'Contenido en italiano (predeterminado)',
  PolishContentDefault: 'Contenido en polaco (predeterminado)',
  DanskCategory: 'Categoría en danés',
  GermanCategory: 'Categoría en alemán',
  EnglishCategory: 'Categoría en inglés',
  BritainCategory: 'Categoría en inglés de Gran Bretaña',
  SpanishCategory: 'Categoría en español',
  FrenchCategory: 'Categoría en francés',
  NederlandsCategory: 'Categoría en holandés',
  NorskCategory: 'Categoría en noruego',
  SvenskaCategory: 'Categoría en sueco',
  ItalianCategory: 'Categoría en italiano',
  PolishCategory: 'Categoría en polaco',
  DanskCategoryDefault: 'Categoría en danés (predeterminado)',
  GermanCategoryDefault: 'Categoría en alemán (predeterminado)',
  EnglishCategoryDefault: 'Categoría en inglés (predeterminado)',
  BritainCategoryDefault: 'Categoría en inglés de Gran Bretaña (predeterminado)',
  SpanishCategoryDefault: 'Categoría en español (predeterminado)',
  FrenchCategoryDefault: 'Categoría en francés (predeterminado)',
  NederlandsCategoryDefault: 'Categoría en holandés (predeterminado)',
  NorskCategoryDefault: 'Categoría en noruego (predeterminado)',
  SvenskaCategoryDefault: 'Categoría en sueco (predeterminado)',
  ItalianCategoryDefault: 'Categoría en italiano (predeterminado)',
  PolishCategoryDefault: 'Categoría en polaco (predeterminado)',
  DanskSubcategory: 'Subcategoría en danés',
  GermanSubcategory: 'Subcategoría en alemán',
  EnglishSubcategory: 'Subcategoría en inglés',
  BritainSubcategory: 'Subcategoría en inglés de Gran Bretaña',
  SpanishSubcategory: 'Subcategoría en español',
  FrenchSubcategory: 'Subcategoría en francés',
  NederlandsSubcategory: 'Subcategoría en holandés',
  NorskSubcategory: 'Subcategoría en holandés',
  SvenskaSubcategory: 'Subcategoría en sueco',
  ItalianSubcategory: 'Subcategoría en italiano',
  PolishSubcategory: 'Subcategoria en polaco',
  DanskSubcategoryDefault: 'Subcategoría en danés (predeterminado)',
  GermanSubcategoryDefault: 'Subcategoría en alemán (predeterminado)',
  EnglishSubcategoryDefault: 'Subcategoría en inglés (predeterminado)',
  BritainSubcategoryDefault: 'Subcategoría en inglés de Gran Bretaña (predeterminado)',
  SpanishSubcategoryDefault: 'Subcategoría en español (predeterminado)',
  FrenchSubcategoryDefault: 'Subcategoría en francés (predeterminado)',
  NederlandsSubcategoryDefault: 'Subcategoría en holandés (predeterminado)',
  NorskSubcategoryDefault: 'Subcategoría en holandés (predeterminado)',
  SvenskaSubcategoryDefault: 'Subcategoría en sueco (predeterminado)',
  ItalianSubcategoryDefault: 'Subcategoría en italiano (predeterminado)',
  PolishSubcategoryDefault: 'Subcategoria en polaco (predeterminado)',
  priorSignatures: 'Firmas previas',
  damageWaiverSignature: 'Firma de exención de daños',
  waterReleaseSignature: 'Firma de liberación de agua',
  errors,
  themes,
  isPDFRecipientListEnable: 'Lista de destinatarios de PDF habilitada en la pantalla de firma del cliente',
  isRejectLoadsEnable: 'Habilitar Cargas Rechazadas Después de Llegar al Sitio',
  rejectLoad_returnedMaterial: 'Botón en paso de retornar material',
  rejectLoad_returnedAndFinalize: 'Boton en paso de retornar material y finalizar',
  driverRejection: 'Rechazar Carga',
  submitRejection: 'Enviar Rechazo',

  costAndPaymentsSummary: 'Resumen de costos y pagos',
  subtotal: 'Subtotal',
  tax: 'Impuesto',
  remainingBalance: 'Saldo restante',
  paymentAmount: 'Monto del pago',
  total: 'Total',
  paymentMethod: 'Metodo de pago',
  paymentProducts: 'Productos',
  unitPrice: 'Precio unitario',
  amount: 'Valor',
  otherPaymentMethod: 'Otro metodo de pago',
  method: 'Metodo',
  paymentOptions: 'Opciones de pago',
  typeAdditionalInfo: 'Escriba la información adicional',
  COD: 'Contraentrega',
  AMEX: 'American Express',
  CASH: 'En efectivo',
  CHECK: 'Cheque',
  'CREDIT CARD': 'Tarjeta de credito',
  ACCOUNT: 'Cuenta abierta',
  UNKNOWN: 'Desconocido',
  remainingMaterial: 'Material restante',
  splitLoad: 'Carga dividida',
  isEnablePaymentAndCostWorkflows: 'Habilitar flujos de trabajo de pagos y costos',
  airTempValue: 'Temperatura del Aire',
  concreteTempValue: 'Temperatura del Concreto',
  cylinderNumber: 'Número de cilindro',
  materialOnTruck: 'Material en camión',
  waterLocation: 'Ubicación del agua',
  dotWorkflow: 'Detalles del lote',
  inspectorSignature: 'Firma del inspector',
  producerSignature: 'Firma del productor',
  addedItems: 'Elementos añadidos',
  producerSignatureContact: 'Nombre del productor',
  inspectorSignatureContact: 'Nombre del inspector',
  waterAtPlant: 'Agua en la planta (gl)',
  waterAtJobsite: 'Agua en el lugar de trabajo (gl)',
  totalActualWater: 'Total de agua real',
  timeDischarged: 'Tiempo de descarga',
  ingredient: 'Ingrediente',
  mcFac: 'MCFac',
  absFac: 'ABSFAC',
  od: 'OD',
  abs: 'ABS',
  ssd: 'SSD',
  freeMst: 'Humedad Libre',
  cydsTarg: 'CYDS Objetivo',
  target: 'Objetivo',
  actual: 'Real',
  freWat: 'Agua Libre',
  trim: 'Recortar',
  batchInformation: 'Información del Lote',
  numberOfBatches: 'Número de Lotes',
  totalWater: 'Agua Total',
  designWater: 'Agua de Diseño',
  toAdd: 'Para Añadir',
  designWC: 'Diseño W/C',
  waterCement: 'Agua / Cemento',
  waterInTruck: 'Agua en Camión',
  adjustWater: 'Ajustar Agua',
  trimWater: 'Recortar Agua',
  plantWeather: 'Clima de la Planta',
  rh: 'RH',
  observationTime: 'Tiempo de Observación',
  address: 'Dirección',
  plant: 'Planta',
  sp: 'S.P. #',
  beginTime: 'Hora de Inicio',
  mixDesc: 'Descripción de la Mezcla',
  qtyOder: 'Cantidad Orden',
  poTag: 'PO #',
  bridge: 'Puente #',
  volume: 'Volumen',
  truckTag: 'Camión #',
  qtyDelivered: 'Cantidad Entregada',
  qtyOrder: 'Cantidad Orden',
  job: 'Trabajo #',
  batchRecord: 'Registro de Lote',
  mixID: 'ID de Mezcla',
  loads: 'Cargas',
  isDot: 'Es DOT',
  tolStatus: 'Estado TOL',
  lastUpdate: 'Última actualización',
  driverClickedFinalize: 'El conductor hizo clic en Finalizar',
  autoSubmit: 'Autoenviar',
  noTaxInformationIsAvailable: 'No hay información fiscal disponible',
  refreshTaxData: 'Actualizar datos fiscales',
};
